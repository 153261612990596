import React, { useState } from 'react';
import { IconButton, Menu, Tooltip } from '@material-ui/core';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { useScope } from '../../../../hooks/useScope';
import Scope from '../../../../types/Scope';
import Spinner from '../../../../se/components/Spinner';

import DetailedReportButton from './DetailedReportButton';

const PDFAnalyticsButton = ({ filter }: { filter: any }) => {
  const [numberOfTasksRunning, setNumberOfTasksRunning] = useState<number>(0);

  const busy = numberOfTasksRunning > 0;

  const scope: Scope = useScope();
  const analyticsPDFs = scope?.hospital?.modules?.analyticsPDFs ?? [];
  const hospitalName = scope?.hospital?.name || '';

  const [anchorEl, setAnchorEl] = useState<
    (EventTarget & HTMLButtonElement) | (EventTarget & HTMLAnchorElement) | null
  >(null);

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleBusy = () => {
    setNumberOfTasksRunning(prev => prev + 1);

    setAnchorEl(null);

    return () => setNumberOfTasksRunning(prev => prev - 1);
  };

  return (
    <>
      <Tooltip title={busy ? 'Preparing report…' : 'Pick Report to Download'} open={busy || undefined}>
        <IconButton onClick={handleClick} color="primary" disabled={busy}>
          {busy ? <Spinner /> : <PictureAsPdfIcon />}
        </IconButton>
      </Tooltip>
      <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        <DetailedReportButton filter={filter} onBusy={handleBusy} />
        {analyticsPDFs.map(pdf => (
          <DetailedReportButton label={pdf.label} filter={filter} onBusy={handleBusy} />
        ))}
      </Menu>
    </>
  );
};

export default PDFAnalyticsButton;
