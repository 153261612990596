import { isDefinedAndNotNull, isNull, isUndefined } from '../../../../se/utilities/check';
import {
  compact,
  concat,
  defaultTo,
  first,
  flatMap,
  flatten,
  flattenDeep,
  get,
  isArray,
  isEmpty,
  isObject,
  map,
  max,
  padEnd,
  pullAt,
  repeat,
} from 'lodash';
import { format } from 'date-fns';

import { getCbResponse, getCbValue, ifArrayGetSecond, respondedNone } from '../components/CheckboxesField';
import { parseSubField } from '../components/OneOfField';
import {
  cbEmptyIcon,
  cbFullIcon,
  defaultFooter,
  elementParserFactory,
  emphasize,
  iconText,
  makeHeader,
  makeHeader28,
  openPdf,
  pad,
  removeQuestion,
  wrapInBrackets,
} from './shared';
import { dayOfService, dayOfService28, dayOfService27, makeBmiSection } from './SummaryPdfGenerator';
import chunk from 'lodash/chunk';
import { patientFooter } from './PdfGenerator';
import { createPdf } from '../../../../vendor/pdfMake';

const wrap = el => (isArray(el) ? el : [el]);

const styles = {
  icon: {
    font: 'Glyphter',
    fontWeight: 400,
  },
  checkmark: {
    font: 'Fontello',
    fontWeight: 400,
    fontSize: 10,
    alignment: 'right',
    color: '#3498DB',
  },
  section: {
    marginBottom: 5,
    fontSize: 7,
  },
  sectionTitle: {
    bold: true,
  },
  subsection: {
    marginLeft: 8,
    fontSize: 7,
  },
  question: {
    opacity: 0.6,
    lineHeight: 1.2,
  },
  answer: {},
  tableheader: {
    bold: true,
  },
  placeholder: {
    opacity: 0.01,
  },
  compactPlaceholder: {
    opacity: 0.3,
  },
  filled: {
    opacity: 1,
  },
  right: {
    alignment: 'right',
    bold: true,
    color: '#00FF00',
  },
};

const clearEmpty = value =>
  isDefinedAndNotNull(value) && isArray(value)
    ? flattenIfSingle(
        value
          .map(v => (isArray(v) ? flatten(clearEmpty(v)) : v))
          .filter(
            v =>
              isDefinedAndNotNull(v) &&
              (isArray(v) ||
                (isArray(v.text) ? !isEmpty(v.text) : isObject(v.text) ? !isEmpty(v.text.text) : !isEmpty(v.text)))
          )
      )
    : value;

const flattenIfSingle = value => (isArray(value) && value.length === 1 && isArray(value[0]) ? value[0] : value);

const correctedSpan = (question, span) => {
  if (['Hepatitis', 'Arrhythmia'].includes(get(question, 'text', question))) {
    return 3;
  } else if (['Tuberculosis', 'COPD'].includes(get(question, 'text', question))) {
    return 2;
  } else if ([].includes(get(question, 'text', question))) {
    return 4;
  } else if (
    [
      'UTI',
      'Difficulty walking a block',
      'Difficulty climbing a flight of stairs',
      'Difficulty lying down flat',
    ].includes(get(question, 'text', question))
  ) {
    return 1;
  } else {
    return span;
  }
};

const styleSubSection = (elements, span) => {
  if (isArray(elements) && elements.length > 0) {
    const questions = elements.map(question =>
      emphasize(
        {
          text: get(question, 'noCheckbox', false)
            ? emphasize(question)
            : iconText(get(question, 'positiveAnswer') ? cbFullIcon : cbEmptyIcon, emphasize(question, false)),
        },
        false
      )
    );

    const chunked = chunk(compact(questions), span) || [];
    if (chunked.length > 0 && chunked[chunked.length - 1].length > 0) {
      const lastRow = chunked[chunked.length - 1];
      const lastEl = lastRow[lastRow.length - 1];
      chunked[chunked.length - 1][lastRow.length - 1] = { ...lastEl, colSpan: span - lastRow.length + 1 };
    }
    const body = chunked.map(pad(span));

    return {
      layout: {
        defaultBorder: false,
        paddingTop: () => -1,
        paddingBottom: () => -1,
      },
      marginLeft: 5,
      width: '100%',
      table: {
        headerRows: 0,
        widths: [...[...new Array(span)].map(_ => `${100 / span}%`)],
        body: body,
      },
    };
  } else if (isObject(elements)) {
    return elements;
  }
};

const calcSpan = (subLen, question, section) => {
  if (section === 'Pre-Existing medical conditions not covered above?' && get(question, 'text', question) === 'Other') {
    return 2;
  }
  if (['Cancer', 'Hepatitis', 'Sleep Apnea', 'Mental Health Diagnosis'].includes(get(question, 'text', question))) {
    return 2;
  } else if (['DVT/Blood Clots', 'Stroke', 'Arrhythmia'].includes(get(question, 'text', question))) {
    return 3;
  } else if (['Tuberculosis', 'Female under 60'].includes(get(question, 'text', question))) {
    return 4;
  } else if (
    [
      'Use Marijuana',
      'Difficulty walking a block',
      'Difficulty climbing a flight of stairs',
      'Difficulty lying down flat',
      'Experience shortness of breath',
      'Utilize any assistive device',
    ].includes(get(question, 'text', question))
  ) {
    return 1;
  } else {
    return Math.max(Math.min(Math.floor(subLen / 2), 4), 1);
  }
};

const separate = section => elements => {
  if (isArray(elements)) {
    // eslint-disable-next-line
    const [question, answer, rest] = elements;
    const clear = flattenIfSingle(defaultTo(isArray(rest) ? flatten(clearEmpty(rest)) : rest, []));
    const subLen = clear ? clear.length : 0;
    const span = calcSpan(subLen, question, section);
    if (get(question, 'text') === 'Implanted devices') {
      // TODO: Generalize for other checkbox nested types
      return [
        {
          colSpan: 4,
          stack: [
            iconText(get(question, 'positiveAnswer') ? cbFullIcon : cbEmptyIcon, question),
            {
              width: '100%',
              columns: rest.map(item => [
                styleSubSection([first(item)], 1),
                { stack: [styleSubSection(item.slice(1), item.length - 1)], style: 'subsection' },
              ]),
            },
          ],
        },
      ];
    } else {
      return [
        {
          colSpan: span,
          stack: [
            get(question, 'noCheckbox', false)
              ? question
              : iconText(get(question, 'positiveAnswer') ? cbFullIcon : cbEmptyIcon, question),
            styleSubSection(clear, correctedSpan(question, span)),
          ],
        },
      ];
    }
  } else {
    return [elements];
  }
};

const layoutRow = (fields, colNum) => {
  let row = [];
  const rest = fields;

  while (row.length < colNum && rest.length > 0) {
    for (let i = 0; i < rest.length; i++) {
      const colSpan = get(rest[i], 'colSpan', 1);
      if (colNum - row.length >= colSpan) {
        row = concat(row, pad(colSpan)([rest[i]]));
        pullAt(rest, i);
        break;
      }

      if (i === rest.length - 1) {
        // No viable candidate
        if (row.length > 0) {
          row = pad(colNum)(row);
        } else {
          console.log('Somethings not right. Breaking row formation', fields);
        }
      }
    }
  }
  return [pad(colNum)(row), rest];
};

const layout = (fields, colNum) => {
  const rows = [];
  let rest = fields;
  while (rest.length > 0) {
    const [row, el] = layoutRow(rest, colNum);
    rows.push(row);
    rest = el;
  }
  return rows;
};

const makeSubsection = (fields, section) => {
  const subsection = compact(flatMap(wrap(fields), separate(section)));
  return layout(subsection, 4);
};

const Sections = ({ sections, value, skipSections, skipUnderline, ...rest }) => {
  const filtered = isObject(skipSections) ? sections.filter(section => !skipSections[section[0]]) : sections;
  return filtered.map(([id, field]) => {
    const formWeight = field.body.weight;
    const formProgress = get(value, `sections.${id}.progress`);

    const fields = parseElement(field.body, {
      value: get(value, `sections.${id}`),
      isRoot: true,
      ...rest,
    });

    const subsection = makeSubsection(fields, field.name);
    return {
      width: '100%',
      layout: {
        fillColor: function (rowIndex, node, columnIndex) {
          if (rowIndex === 0) {
            return '#d3d3d3';
          } else if (rowIndex % 2 === 0) {
            return '#EBEBEB';
          } else {
            return null;
          }
        },
        paddingTop: () => 1,
        paddingBottom: () => -1,
        defaultBorder: false,
      },
      style: 'section',
      table: {
        widths: ['25%', '25%', '25%', '25%'],
        body: [
          [
            {
              text: field.name,
              style: 'sectionTitle',
              colSpan: 3,
            },
            '',
            '',
            {
              text: formProgress === formWeight ? '\uE800' : '',
              style: 'checkmark',
            },
          ],
          ...subsection,
        ],
      },
    };
  });
};

const ObjectField = ({ schema, value, ...rest }) =>
  flatMap(schema, ([id, field]) =>
    parseElement(field, { value: get(value, `schema.${id}`), ...rest, type: get(field, 'type') })
  );

const trimPad = (string, len) => padEnd(string, len, defaultTo(string, '').length > 0 ? ' ' : '_');

const decorateAnswer = (answer, question, options) => {
  if (answer) {
    if (question === 'Cigarettes per day:') {
      return `${answer} daily`;
    } else if (question === 'What year did you quit?') {
      return `Quit ${answer}`;
    } else if (question === 'When was your last menstrual period?') {
      return `Last period: ${answer}`;
    } else if (question === 'Location' || question === 'Wound location') {
      return `Location: ${answer}`;
    } else if (question === 'Body Part') {
      return `Body Part: ${answer}`;
    } else if (question.startsWith('Usage')) {
      return `Usage: ${answer}`;
    } else if (question.startsWith('Year')) {
      return `Year: ${answer}`;
    } else if (question.startsWith('How Many')) {
      return `How Many: ${answer}`;
    } else if (question.startsWith('Type')) {
      return `Type: ${answer}`;
    } else if (question.startsWith('When was it placed')) {
      return `Placed: ${answer}`;
    } else if (question.startsWith('How many liters')) {
      return `Liters: ${answer}`;
    } else if (question.startsWith('Procedure')) {
      return `Procedure: ${answer}`;
    } else if (question.startsWith('Please explain your disability')) {
      return `Disability: ${answer}`;
    } else if (question.startsWith('Please explain')) {
      if (get(options, 'labelExplain')) {
        return `Explain: ${answer}`;
      }
      return answer;
    } else if (question.startsWith('Extremity')) {
      return `Extremity: ${answer}`;
    } else if (question.startsWith('Last')) {
      return `Last: ${answer}`;
    } else if (question.startsWith('Where')) {
      return `Where: ${answer}`;
    } else if (question.startsWith('When was your last seizure? MM/YY')) {
      return `Last seizure: ${answer}`;
    }
  }

  return answer;
};

const getPadding = question => {
  if (question.startsWith('Year')) {
    return 12;
  } else if (question.startsWith('Type')) {
    return 10;
  } else if (question.startsWith('Last')) {
    return 10;
  } else if (question.startsWith('What year did you quit?')) {
    return 5;
  } else if (question.startsWith('Location')) {
    return 10;
  } else if (question.startsWith('When was it placed')) {
    return 14;
  } else if (question.startsWith('When was your last seizure? MM/YY')) {
    return 6;
  } else if (question.startsWith('Body Part')) {
    return 20;
  } else if (question.startsWith('Usage')) {
    return 20;
  } else if (question.startsWith('Extremity')) {
    return 25;
  } else if (question.startsWith('Cigarettes per day')) {
    return 25;
  } else if (question.startsWith('When was your last menstrual period')) {
    return 25;
  } else if (question.startsWith('How Many')) {
    return 10;
  }
  return 34;
};

const TextField = ({
  label,
  value,
  question,
  type,
  highlightAnswer,
  positiveAnswer,
  noCheckbox,
  skipDecoration,
  labelExplain,
}) => {
  const text = get(value, 'text', isEmpty(value) ? '' : value);

  if (type === 'Text' || type === 'LongText' || type === 'Medication') {
    const string = isObject(text) ? get(value, 'text', '') : text;
    if (string.length > 0) {
      return {
        text: skipDecoration ? string : decorateAnswer(string, question, { labelExplain }),
        noCheckbox: true,
        italics: true,
        color: 'red',
      };
    } else {
      return {
        text: decorateAnswer(repeat('_', getPadding(question)), question, { labelExplain }),
        style: 'filled',
        noCheckbox: true,
      };
    }
  }

  if (type === 'Date') {
    const padWithZero = value => (value.length === 1 ? `0${value}` : value);
    const day = value?.date?.day || '';
    const month = value?.date?.month || '';
    const year = value?.date?.year || '';
    const date = question
      ? `${question}: ${padWithZero(month)}-${padWithZero(day)}-${year}`
      : `${padWithZero(month)}-${padWithZero(day)}-${year}`;
    if (day.length > 0 && month.length > 0 && year.length > 0) {
      return {
        text: skipDecoration ? date : decorateAnswer(date, question, { labelExplain }),
        noCheckbox: true,
        italics: true,
        color: 'red',
      };
    } else {
      return {
        text: decorateAnswer(repeat('_', getPadding(question)), question, { labelExplain }),
        style: 'filled',
        noCheckbox: true,
      };
    }
  }

  const answer = isObject(label) && label.text ? label.text : label;
  return {
    text: removeQuestion(answer),
    color: highlightAnswer ? 'red' : 'black',
    value: text,
    style: positiveAnswer ? 'filled' : 'filled',
    positiveAnswer: !!positiveAnswer,
    noCheckbox,
  };
};

const TextValueField = ({ label, value, ...rest }) => TextField({ label: value, value, question: label, ...rest });

const ListField = ({ item, value, label, ...rest }) => {
  const list = get(value, 'list', [])
    .filter(item => !isEmpty(get(item, 'schema')))
    .map(answer => parseElement(item, { ...rest, value: answer, noColor: true }));
  return list.map(el => wrapInBrackets(el, true, false, 'red', { noCheckbox: true }));
};
const NO_ANS = ' ';

const parseNestedField = ({ checkbox, value, isRoot }) => {
  /// ????
  if (['Pacemaker/Defibrillator Combo'].includes(checkbox[0])) {
    return [];
  }

  const el = parseElement(checkbox[1], {
    value: ifArrayGetSecond(get(getCbValue(value), checkbox[0])),
  });

  const isChildText = ['Text', 'OneOf'].includes(get(checkbox, '[1].type')) && !isRoot;

  return compact(
    flatten([
      TextField({
        label: isChildText
          ? `${checkbox[0]} ${get(checkbox, '[1].type') === 'Text' ? get(el, 'text') : get(el, '[0][0].text')}`
          : checkbox[0],
        value: get(getCbResponse(value), checkbox[0]) ? 'Yes' : respondedNone(value) ? 'No' : NO_ANS,
        positiveAnswer: get(getCbResponse(value), checkbox[0]),
        highlightAnswer: get(getCbResponse(value), checkbox[0]) && get(checkbox[1], 'important'),
      }),
      !isChildText ? (get(checkbox, '[1].type') === 'Checkboxes' ? flattenIfSingle(el) : el) : undefined,
    ])
  );
};

const CheckboxesField = ({ checkboxes, question, value, isRoot }) => {
  const list = checkboxes
    .map(checkbox =>
      isArray(checkbox)
        ? parseNestedField({ checkbox, value, isRoot })
        : TextField({
            label: checkbox,
            positiveAnswer: get(getCbValue(value), checkbox),
            value: `${get(getCbValue(value), checkbox) ? 'Yes' : respondedNone(value) ? 'No' : NO_ANS}`,
          })
    )
    .filter(_ => !(isArray(_) && (isNull(_[0]) || _.length === 0)) && !isNull(_) && !isUndefined(_));

  if (!question && isRoot) {
    return map(list, item => (isArray(item) ? [item[0], item[0].value, item.slice(1)] : [item, item.value]));
  }

  const response =
    list && list.length > 0 && get(value, 'progress') > 0 && !isEmpty(get(value, 'checkboxes'))
      ? 'Yes'
      : get(value, 'progress') > 0
      ? 'No'
      : NO_ANS;

  return [
    compact([
      isRoot
        ? TextField({
            label: question,
            value: question,
            noCheckbox: question === 'Do you have any of the following implanted devices?',
          })
        : undefined,
      isRoot ? response : undefined,
      list,
    ]),
  ];
};

const YesNoField = ({ ifYes, ifNo, value, question, important, isRoot }) => {
  const response = get(value, 'yes');
  const yesNo = isArray(response) ? response[0] : response;
  if (isRoot && get(ifYes, 'type') === 'List') {
    return flatMap([
      !!response && ifYes ? parseElement(ifYes, { value: response[1], skipDecoration: true }) : undefined,
      !important && !!response && ifNo ? parseElement(ifNo, { value: response[1], skipDecoration: true }) : undefined,
    ]);
  }
  return [
    [
      TextField({
        label: question,
        value: yesNo ? 'Yes' : isUndefined(yesNo) || isNull(yesNo) ? NO_ANS : 'No',
        highlightAnswer: important,
        positiveAnswer: !!yesNo,
      }),
      isRoot
        ? TextValueField({
            label: question,
            value: yesNo ? 'Yes' : isUndefined(yesNo) || isNull(yesNo) ? NO_ANS : 'No',
            positiveAnswer: !!yesNo,
          })
        : undefined,
      flattenDeep([
        parseElement(ifYes, { value: response ? response[1] : undefined, labelExplain: true }),
        parseElement(ifNo, { value: response ? response[1] : undefined, labelExplain: true }),
      ]),
    ],
  ];
};

const OneOfField = ({ label, question, value, isRoot, ...field }) => {
  const response = isArray(value) ? get(value[1], 'option') : get(value, 'option');
  const subField = parseSubField(response, field);
  const space = max(
    get(field, 'options', [])
      .filter(_ => typeof _ === 'string')
      .map(removeQuestion)
      .map(s => s.length)
  );

  if (subField) {
    const highlight = response[0] === 'Current Smoker';
    return [
      TextValueField({
        label: label,
        value: trimPad(response[0], 40),
        positiveAnswer: isDefinedAndNotNull(response[0]),
        highlightAnswer: isDefinedAndNotNull(response[0]) && highlight,
      }),
      parseElement(subField, { value: response[1] }),
    ];
  } else {
    if (
      [
        'The activity that most closely represents your level of physical activity is',
        'What is your cigarette smoking status?',
      ].includes(label)
    ) {
      // whatever ...
      return [
        {
          text: [
            TextValueField({
              noCheckbox: true,
              label: label,
              value: `${
                label && !(['How often?'].includes(label) || label.startsWith('How long')) ? `${label}: ` : ''
              }`,
              positiveAnswer: isDefinedAndNotNull(response),
            }),
            TextValueField({
              noCheckbox: true,
              label: label,
              value: ` ${trimPad(response, Math.min(space, 34))}`,
              positiveAnswer: isDefinedAndNotNull(response),
              highlightAnswer: isDefinedAndNotNull(response),
            }),
          ],
        },
      ];
    }
    return [
      [
        TextValueField({
          noCheckbox: true,
          label: label,
          value: `${
            label &&
            !(
              ['How often?', 'Choose an option', 'Last Experienced', 'Frequency'].includes(label) ||
              label.startsWith('How long')
            )
              ? `${label}: `
              : ''
          } ${trimPad(response, Math.min(space, 34))}`,
          positiveAnswer: isDefinedAndNotNull(response),
          highlightAnswer:
            [
              'Relationship',
              'Choose an option',
              'Last Experienced',
              'Last Used',
              'Frequency',
              'Type',
              'First',
              'Second',
              'Third',
              'Area affected',
              'Severity',
              'Rescue inhaler use',
              'How often used',
              'Liters',
              'How many days per week do you usually drink beer, wine or liquor?',
              'When you do drink, how many drinks do you have?',
              '1. Usual pain level? (1=very mild, 10=worst imaginable)',
            ].includes(label) && response,
        }),
      ],
    ];
  }
};

export const parseElement = elementParserFactory({
  Sections: Sections,
  Object: ObjectField,
  Text: TextValueField,
  LongText: TextValueField,
  Checkboxes: CheckboxesField,
  List: ListField,
  YesNo: YesNoField,
  OneOf: OneOfField,
  Medication: TextValueField,
  Date: TextValueField,
});

export const docDefinitionTemplate = (procedure, margins = [40, 20, 40, 20]) => ({
  pageSize: 'LETTER',
  pageMargins: margins,
  styles,
  defaultStyle: {
    fontSize: 9,
    lineHeight: 1.4,
  },
  footer: defaultFooter(patientFooter(procedure)),
});

export const makeLayout = (questions, answers) => {
  const body = parseElement(questions, {
    value: answers,
    skipSections: {
      medications: true,
      allergies: true,
      demographicInformation: true,
      documentElectronicSignature: true,
    },
  });

  const sections = [body[2], body[3], body[4], body[1], body[5], body[6], body[7], ...body.slice(8), body[0]];

  return {
    fontSize: 8,
    stack: sections,
  };
};

export const makeLayout1 = (questions, answers) => {
  const body = parseElement(questions, {
    value: answers,
    skipSections: {
      medications: true,
      demographicInformation: true,
      documentElectronicSignature: true,
    },
  });
  const sections = [body[3], body[2], body[4], body[1], body[5], body[6], body[7], ...body.slice(8), body[0]];

  return {
    fontSize: 8,
    stack: sections,
  };
};

export const makeLabSection = answers => {
  const lab = get(answers, 'sections.demographicInformation.schema.q2');
  const ekg = get(answers, 'sections.demographicInformation.schema.q3');

  const labLocation = get(lab, 'yes[1].text', '');
  const ekgLocation = get(ekg, 'yes[1].text', '');

  return {
    stack: [
      lab && lab.yes ? `Lab drawn in the past 6 months at ${labLocation}` : 'No lab drawn in past 6 months',
      ekg && ekg.yes ? `Had EKG in the past 6 months at ${ekgLocation}` : 'No EKG done in past 6 months.',
    ],
    fontSize: 8,
  };
};

export const makeUCSDLabSection = answers => {
  const lab = get(answers, 'sections.demographicInformation.schema.q2');
  const ekg = get(answers, 'sections.demographicInformation.schema.q3');

  const labLocation = get(lab, 'yes[1].text', '');
  const ekgLocation = get(ekg, 'yes[1].text', '');

  return {
    stack: [
      lab && lab.yes ? `Lab drawn in the past 30 days at ${labLocation}` : 'No lab drawn in past 30 days',
      ekg && ekg.yes ? `Had EKG in the past 30 days at ${ekgLocation}` : 'No EKG done in past 30 days.',
    ],
    fontSize: 8,
  };
};

export const makeBrainardLabSection = answers => {
  const lab = get(answers, 'sections.demographicInformation.schema.q2');
  const ekg = get(answers, 'sections.demographicInformation.schema.q3');
  const admission = get(answers, 'sections.demographicInformation.schema.q5')

  const labLocation = get(lab, 'yes[1].text', '');
  const ekgLocation = get(ekg, 'yes[1].text', '');
  const admissionLocation = get(admission, 'yes[1].text', '');

  return {
    stack: [
      lab && lab.yes ? `Lab drawn in the past 6 months at ${labLocation}` : 'No lab drawn in past 6 months',
      ekg && ekg.yes ? `Had EKG in the past 6 months at ${ekgLocation}` : 'No EKG done in past 6 months.',
      admission && admission.yes ? `Done Admission Test at ${admissionLocation}` : 'No admission Test done',
    ],
    fontSize: 8,
  };
};

export const makeVisitSection = answers => {
  const primaryCareDoctorVisit = get(answers, 'sections.demographicInformation.schema.primaryCareDoctorVisit.text');
  const cardiologistVisit = get(answers, 'sections.demographicInformation.schema.cardiologistVisit.text');

  return {
    stack: [
      `Last visit to the primary care doctor: ${primaryCareDoctorVisit || ''}`,
      `Last visit to the cardiologist: ${cardiologistVisit || ''}`,
    ],
    fontSize: 8,
  };
};

export const makePrimaryCareDoctor = answers => {
  const doctorName = get(answers, 'sections.demographicInformation.schema.primaryCareDoctor.text', '');
  const doctorPhone = get(answers, 'sections.demographicInformation.schema.primaryCareDoctorPhone.text');
  return {
    text: `Primary Care Doctor: ${doctorName}${doctorPhone && doctorPhone.length > 0 ? ` (${doctorPhone})` : ''}`,
    fontSize: 8,
    marginBottom: -2,
  };
};

export const makeCityStateZip = answers => {
  const city = get(answers, 'sections.demographicInformation.schema.citystatezip.cityStateZip.city', '');
  const state = get(answers, 'sections.demographicInformation.schema.citystatezip.cityStateZip.state', '');
  const zipCode = get(answers, 'sections.demographicInformation.schema.citystatezip.cityStateZip.zipCode', '');
  return `${city || ''} ${state || ''} ${zipCode || ''}`.replace(/\W+/g, ' ').trim();
};

export const makeCardioDoctor = answers => {
  const doctorName = get(answers, 'sections.demographicInformation.schema.cardiologist.text', '');
  const doctorPhone = get(answers, 'sections.demographicInformation.schema.cardiologistPhone.text');
  const doctorOtherSpecialist = get(answers, 'sections.demographicInformation.schema.specialist.text');

  return [
    {
      text: `Cardiologist: ${doctorName}${doctorPhone && doctorPhone.length > 0 ? ` (${doctorPhone})` : ''}`,
      fontSize: 8,
      marginBottom: -2,
    },
    {
      text: `Other Physician Specialist: ${!!doctorOtherSpecialist ? doctorOtherSpecialist : ''}`,
      fontSize: 8,
      marginBottom: -2,
    },
  ];
};

export const makeBrainardCardioDoctor = answers => {
  const doctorName = get(answers, 'sections.demographicInformation.schema.cardiologist.yes[1].schema.cardiologistName.text', '');
  const doctorPhone = get(answers, 'sections.demographicInformation.schema.cardiologist.yes[1].schema.cardiologistPhone.text');
  const doctorOtherSpecialist = get(answers, 'sections.demographicInformation.schema.specialist.text');

  return [
    {
      text: `Cardiologist: ${doctorName}${doctorPhone && doctorPhone.length > 0 ? ` (${doctorPhone})` : ''}`,
      fontSize: 8,
      marginBottom: -2,
    },
    {
      text: `Other Physician Specialist: ${!!doctorOtherSpecialist ? doctorOtherSpecialist : ''}`,
      fontSize: 8,
      marginBottom: -2,
    },
  ];
};

export const makeGender = answers => {
  const sexBirth = get(answers, 'sections.demographicInformation.schema.sex_birth.option', '');
  const genderIdentity = get(answers, 'sections.demographicInformation.schema.gender_identity.option');

  console.log(sexBirth)
  return [
    {
      text: `Sex at birth: ${!!sexBirth ? sexBirth : ''} / Gender Identity: ${!!genderIdentity ? genderIdentity : ''}`,
      fontSize: 8,
      marginBottom: -2,
    }
  ];
};

export const makeCaretaker = answers => {
  const hasCaretaker = get(answers, 'sections.demographicInformation.schema.q5.yes[0]', false);
  const caretakerName = get(answers, 'sections.demographicInformation.schema.q5.yes[1].schema.name.text', '');
  const caretakerPhone = get(answers, 'sections.demographicInformation.schema.q5.yes[1].schema.number.text', '');
  return hasCaretaker && caretakerName
    ? {
        text: `The person who will take you home: ${caretakerName}${
          caretakerPhone?.length > 0 ? ` (${caretakerPhone})` : ''
        }`,
        fontSize: 8,
      }
    : undefined;
};

export const makeConcernsSection = answers => {
  const concern = get(answers, 'sections.demographicInformation.schema.q4.yes', '');
  const concernText = get(concern, '[1].text', `Patient didn‘t specify`);

  return {
    text: concern ? `Concerns: ${concernText}` : 'No concerns',
    fontSize: 8,
    marginBottom: -2,
  };
};

export const makeNotesPage = notes => ({
  pageBreak: 'before',
  marginTop: 20,
  stack: [
    {
      fontSize: 12,
      text: 'PreOp Notes',
      marginBottom: 20,
    },
    {
      layout: {
        hLineWidth: (i, node) => {
          if (i === 0 || i === node.table.body.length) {
            return 0;
          }

          return i === 1 ? 0.8 : 0.6;
        },
        vLineWidth: (i, node) => {
          if (i === 0 || i === node.table.widths.length) {
            return 0;
          }

          return [1, 3, node.table.widths.length - 1].includes(i) ? 0.8 : 0.6;
        },
      },
      table: {
        headerRows: 1,
        widths: ['20%', '20%', '*'],
        body: [
          ['Time', 'Person', 'Note'],
          ...notes.map(note => [
            format(note.timestamp, 'MMMM DD, YYYY HH:mm'),
            get(note, 'user.name', 'Administrator'),
            note.text || '',
          ]),
        ],
      },
    },
  ],
});

export const generateFullPdf = ({
  questions,
  answers,
  procedure,
  hospitalName,
  preOpBy,
  preOpAt,
  scheduleProvider,
  notes,
  showPreOpNote,
}) => {
  const location = makeCityStateZip(answers);
  const header = makeHeader({
    procedure,
    hospitalName,
    marginBottom: 5,
    preOpInfo: preOpAt ? { preOpBy, preOpAt } : undefined,
    scheduleProvider,
    location,
  });
  const body = makeLayout(questions, answers);

  const docDefinition = {
    ...docDefinitionTemplate(procedure),
    info: { title: `Full Form Patient ${procedure.id}` },
    content: [
      header,
      {
        columns: [
          {
            stack: [
              makePrimaryCareDoctor(answers),
              makeCardioDoctor(answers),
              makeBmiSection(answers, { marginBottom: 0, fontSize: 8, color: 'black' }),
            ],
          },
          {
            stack: [makeLabSection(answers), makeConcernsSection(answers)],
          },
        ],
      },
      body,
      dayOfService,
      notes.length > 0 && showPreOpNote ? makeNotesPage(notes) : undefined,
    ],
  };

  openPdf(createPdf(docDefinition), `Full_Form_Questionnaire_Patient_${procedure.id}`);
};

export const generateFullPdf1 = ({
  questions,
  answers,
  procedure,
  hospitalName,
  preOpBy,
  preOpAt,
  scheduleProvider,
  notes,
  showPreOpNote,
}) => {
  const location = makeCityStateZip(answers);
  const header = makeHeader({
    procedure,
    hospitalName,
    marginBottom: 5,
    preOpInfo: preOpAt ? { preOpBy, preOpAt } : undefined,
    scheduleProvider,
    location,
  });
  const body = makeLayout1(questions, answers);

  const docDefinition = {
    ...docDefinitionTemplate(procedure),
    info: { title: `Full Form Patient ${procedure.id}` },
    content: [
      header,
      {
        columns: [
          {
            stack: [
              makePrimaryCareDoctor(answers),
              makeCardioDoctor(answers),
              makeBmiSection(answers, { marginBottom: 0, fontSize: 8, color: 'black' }),
            ],
          },
          {
            stack: [makeLabSection(answers), makeConcernsSection(answers)],
          },
        ],
      },
      body,
      dayOfService,
      notes.length > 0 && showPreOpNote ? makeNotesPage(notes) : undefined,
    ],
  };

  openPdf(createPdf(docDefinition), `Full_Form_Questionnaire_Patient_${procedure.id}`);
};

export const generateFullPdfViewHospital2 = ({
  questions,
  answers,
  procedure,
  hospitalName,
  preOpBy,
  preOpAt,
  scheduleProvider,
  notes,
  showPreOpNote,
}) => {
  const location = makeCityStateZip(answers);
  const header = makeHeader({
    procedure,
    hospitalName,
    marginBottom: 5,
    preOpInfo: preOpAt ? { preOpBy, preOpAt } : undefined,
    scheduleProvider,
    location,
  });
  const body = makeLayout(questions, answers);

  const docDefinition = {
    ...docDefinitionTemplate(procedure),
    info: { title: `Full Form Patient ${procedure.id}` },
    content: [
      header,
      {
        columns: [
          {
            stack: [
              makePrimaryCareDoctor(answers),
              makeCardioDoctor(answers),
              makeBmiSection(answers, { marginBottom: 0, fontSize: 8, color: 'black' }),
            ],
          },
          {
            columns: [
              {
                stack: [makeVisitSection(answers), makeUCSDLabSection(answers), makeConcernsSection(answers)],
              },
            ],
          },
        ],
      },
      makeCaretaker(answers),
      body,
      notes.length > 0 && showPreOpNote ? makeNotesPage(notes) : undefined,
    ],
  };

  openPdf(createPdf(docDefinition), `Full_Form_Questionnaire_Patient_${procedure.id}`);
};

// Yes, it’s called Full PDF 2 ... Basically same thing without footer.
export const generateFullPdfHospital3 = ({
  questions,
  answers,
  procedure,
  hospitalName,
  preOpBy,
  preOpAt,
  scheduleProvider,
  notes,
  showPreOpNote,
}) => {
  const header = makeHeader({
    procedure,
    hospitalName,
    marginBottom: 5,
    preOpInfo: preOpAt ? { preOpBy, preOpAt } : undefined,
    scheduleProvider,
  });
  const body = makeLayout(questions, answers);

  const docDefinition = {
    ...docDefinitionTemplate(procedure, [40, 40, 40, 40]),
    info: { title: `Full Form Patient ${procedure.id}` },
    content: [
      header,
      {
        columns: [
          {
            stack: [
              makePrimaryCareDoctor(answers),
              makeCardioDoctor(answers),
              makeBmiSection(answers, { marginBottom: 0, fontSize: 8, color: 'black' }),
            ],
          },
          {
            stack: [makeLabSection(answers), makeConcernsSection(answers)],
          },
        ],
      },
      body,
      {
        marginTop: 20,
        style: { lineHeight: 1.6, fontSize: 8, opacity: 0.8 },
        text: `The information in this document is accurate.
           Patient Signature________________________________________________________________________ Date __________________________ Time ____________________`,
      },
      notes.length > 0 && showPreOpNote ? makeNotesPage(notes) : undefined,
    ],
  };

  openPdf(createPdf(docDefinition), `Full_Form_Questionnaire_Patient_${procedure.id}`);
};

// Yes, it’s called Full PDF 3 ... Basically same thing without footer and couple more things.
export const generateFullPdfHospital17 = ({
  questions,
  answers,
  procedure,
  hospitalName,
  preOpBy,
  preOpAt,
  scheduleProvider,
  notes,
  showPreOpNote,
}) => {
  const header = makeHeader({
    procedure,
    hospitalName,
    marginBottom: 5,
    preOpInfo: preOpAt ? { preOpBy, preOpAt } : undefined,
    scheduleProvider,
  });
  const body = makeLayout(questions, answers);

  const docDefinition = {
    ...docDefinitionTemplate(procedure, [40, 40, 40, 40]),
    info: { title: `Full Form Patient ${procedure.id}` },
    content: [
      header,
      {
        stack: [makeBmiSection(answers, { marginBottom: 0, fontSize: 8, color: 'black' })],
      },
      body,
      notes.length > 0 && showPreOpNote ? makeNotesPage(notes) : undefined,
    ],
  };

  openPdf(createPdf(docDefinition), `Full_Form_Questionnaire_Patient_${procedure.id}`);
};

// custom full form for Walnut Creek
export const generateFullPdf28 = ({
  questions,
  answers,
  procedure,
  hospitalName,
  preOpBy,
  preOpAt,
  scheduleProvider,
  notes,
  showPreOpNote,
}) => {
  const location = makeCityStateZip(answers);
  const header = makeHeader28({
    procedure,
    hospitalName,
    marginBottom: 5,
    preOpInfo: preOpAt ? { preOpBy, preOpAt } : undefined,
    scheduleProvider,
    location,
  });
  const body = makeLayout(questions, answers);

  const docDefinition = {
    ...docDefinitionTemplate(procedure),
    info: { title: `Full Form Patient ${procedure.id}` },
    content: [
      header,
      {
        columns: [
          {
            stack: [
              makePrimaryCareDoctor(answers),
              makeCardioDoctor(answers),
              makeBmiSection(answers, { marginBottom: 0, fontSize: 8, color: 'black' }),
            ],
          },
          {
            stack: [makeLabSection(answers), makeConcernsSection(answers)],
          },
        ],
      },
      body,
      dayOfService28,
      notes.length > 0 && showPreOpNote ? makeNotesPage(notes) : undefined,
    ],
  };

  openPdf(createPdf(docDefinition), `Full_Form_Questionnaire_Patient_${procedure.id}`);
};

export const generateFullPdfView2Hospital25 = ({
  questions,
  answers,
  procedure,
  hospitalName,
  preOpBy,
  preOpAt,
  scheduleProvider,
  notes,
  showPreOpNote,
}) => {
  const location = makeCityStateZip(answers);
  const header = makeHeader({
    procedure,
    hospitalName,
    marginBottom: 5,
    preOpInfo: preOpAt ? { preOpBy, preOpAt } : undefined,
    scheduleProvider,
    location,
  });
  const body = makeLayout(questions, answers);

  const docDefinition = {
    ...docDefinitionTemplate(procedure),
    info: { title: `Full Form Patient ${procedure.id}` },
    content: [
      header,
      {
        columns: [
          {
            stack: [
              makePrimaryCareDoctor(answers),
              makeBrainardCardioDoctor(answers),
              makeGender(answers),
              makeBmiSection(answers, { marginBottom: 0, fontSize: 8, color: 'black' }),
            ],
          },
          {
            stack: [makeBrainardLabSection(answers), makeConcernsSection(answers)],
          },
        ],
      },
      body,
      dayOfService,
      notes.length > 0 && showPreOpNote ? makeNotesPage(notes) : undefined,
    ],
  };

  openPdf(createPdf(docDefinition), `Full_Form_Questionnaire_Patient_${procedure.id}`);
};

export const generateFullPdf27 = ({
  questions,
  answers,
  procedure,
  hospitalName,
  preOpBy,
  preOpAt,
  scheduleProvider,
  notes,
  showPreOpNote,
}) => {
  const location = makeCityStateZip(answers);
  const header = makeHeader({
    procedure,
    hospitalName,
    marginBottom: 5,
    preOpInfo: preOpAt ? { preOpBy, preOpAt } : undefined,
    scheduleProvider,
    location,
  });
  const body = makeLayout(questions, answers);

  const docDefinition = {
    ...docDefinitionTemplate(procedure),
    info: { title: `Full Form Patient ${procedure.id}` },
    content: [
      header,
      {
        columns: [
          {
            stack: [
              makePrimaryCareDoctor(answers),
              makeCardioDoctor(answers),
              makeBmiSection(answers, { marginBottom: 0, fontSize: 8, color: 'black' }),
            ],
          },
          {
            stack: [makeLabSection(answers), makeConcernsSection(answers)],
          },
        ],
      },
      body,
      dayOfService27,
      notes.length > 0 && showPreOpNote ? makeNotesPage(notes) : undefined,
    ],
  };

  openPdf(createPdf(docDefinition), `Full_Form_Questionnaire_Patient_${procedure.id}`);
};
