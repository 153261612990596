import get from 'lodash/get';
import isArray from 'lodash/isArray';
import { calculateBMI } from '../components/BodyMassIndexField';
import { isNull, isUndefined } from '../../../../se/utilities/check';
import last from 'lodash/last';
import flatMap from 'lodash/flatMap';
import { isEmpty } from 'lodash/fp';
import { getCbResponse, getCbValue, ifArrayGetSecond, respondedNone } from '../components/CheckboxesField';
import { parseSubField } from '../components/OneOfField';
import compact from 'lodash/compact';
import merge from 'lodash/merge';
import chunk from 'lodash/chunk';
import {
  cbEmptyIcon,
  cbFullIcon,
  defaultFooter,
  elementParserFactory,
  makeHeader,
  makeHeader28,
  makeHeader27,
  openPdf,
  pad,
  iconText,
} from './shared';
import { format } from 'date-fns';
import CityStateZip from '../components/CityStateZip';
import { createPdf } from '../../../../vendor/pdfMake';
import parseJson from '../utils/parseJson';
import { ContactSupportOutlined } from '@material-ui/icons';
const wrap = el => (isArray(el) ? el : [el]);

const styles = {
  icon: { font: 'Glyphter' },
  checkmark: { font: 'Fontello' },
  section: {
    marginBottom: 20,
  },
  sectionTitle: {
    marginBottom: 5,
    fontSize: 12,
  },
  subsection: {
    marginLeft: 8,
    marginRight: 8,
    fontSize: 8,
  },
  question: {
    opacity: 0.6,
    lineHeight: 1.6,
  },
  answer: {},
  tableheader: {
    bold: true,
  },
};

const Line = `<svg width="530%" height="1"><line x1="0" y1="0" x2="100%" y2="0" style="stroke:rgba(0,0,0, 0.1); stroke-width: 1" /></svg>`;

const sectionHasValue = section => {
  if (isArray(section) && section.length > 0) {
    return true;
  } else {
    return !!section;
  }
};

const Sections = ({ sections, value, ...rest }) =>
  compact(
    sections.map(([id, field]) => {
      const subsection = parseElement(field.body, { value: get(value, `sections.${id}`), isRoot: true, ...rest });
      if (sectionHasValue(subsection)) {
        return {
          stack: [{ text: field.name, style: 'sectionTitle' }, ...wrap(subsection)],
          style: 'section',
        };
      } else {
        return null;
      }
    })
  );

const ObjectField = ({ schema, value, ...rest }) =>
  compact(flatMap(schema, ([id, field]) => parseElement(field, { value: get(value, `schema.${id}`), ...rest })));

const SingleTextField = ({ label, value, highlightAnswer, flat, parent }) => {
  const text = get(value, 'text', value);
  if (flat) {
    return { [parent || label]: typeof text === 'string' ? text : '' };
  }
  return {
    columns: [
      { text: label, style: 'question', width: '60%' },
      {
        text: typeof text === 'string' ? text : '',
        width: '*',
        color: highlightAnswer ? 'red' : 'black',
        style: 'answer',
      },
    ],
  };
};

const LongTextField = props => SingleTextField(props);

const makeTable = elements => {
  // This will only work for depth 1 list elements, needs improvement
  if (isArray(elements) && elements.length > 0) {
    const data = elements.map(el => merge(...el));
    const header = elements[0].map(el => Object.keys(el)[0]);
    return {
      layout: 'lightHorizontalLines',
      table: {
        headerRows: 1,
        body: [elements[0].map(el => Object.keys(el)[0]), ...data.map(row => header.map(col => get(row, col, '')))],
      },
    };
  }

  return { text: 'None', style: 'question' };
};

const ListField = ({ item, value, label, ...rest }) => {
  const list = get(value, 'list', []);
  return makeTable(list.map(answer => parseElement(item, { ...rest, value: answer, flat: true, omitUnderline: true })));
};

const parseNestedField = ({ checkbox, value, flat }) => {
  const el = parseElement(checkbox[1], {
    value: ifArrayGetSecond(get(getCbValue(value), checkbox[0])),
    omitUnderline: true,
    flat,
  });

  return [
    SingleTextField({
      label: checkbox[0],
      value: get(getCbResponse(value), checkbox[0]) ? 'Yes' : respondedNone(value) ? 'No' : '',
      highlightAnswer: get(getCbResponse(value), checkbox[0]) && get(checkbox[1], 'important'),
      flat,
    }),
    { svg: Line },
    get(getCbResponse(value), checkbox[0]) ? el : undefined,
  ];
};

const removeLastLine = list => {
  const el = last(last(list));
  if (el && 'svg' in el) {
    return [...list.slice(0, -1), last(list)[0]];
  }
  return list;
};

const CheckboxesField = ({ checkboxes, question, value, flat }) => {
  const list = removeLastLine(
    checkboxes.map((checkbox, i) =>
      isArray(checkbox)
        ? parseNestedField({ checkbox, value, flat })
        : [
            SingleTextField({
              label: checkbox,
              value: `${get(getCbValue(value), checkbox) ? 'Yes' : respondedNone(value) ? 'No' : ''}`,
              flat,
            }),
            checkboxes.length - 1 > i ? { svg: Line } : undefined,
          ]
    )
  );

  return [{ text: question, style: 'question' }, list];
};

const DateOfBirthField = ({ value }) => {
  const { month, day, year } = get(value, 'dateOfBirth', {});
  return SingleTextField({ label: 'Date of Birth ', value: value ? `${month}/${day}/${year}` : '' });
};

const BodyMassIndexField = ({ value }) => {
  const { heightFt, heightIn, weightLb } = get(value, 'bodyMassIndex', {});
  return [
    SingleTextField({ label: 'Height', value: heightIn && heightFt ? `${heightFt}'${heightIn}''` : '-' }),
    SingleTextField({ label: 'Weight', value: weightLb ? `${weightLb} lb` : '-' }),
    SingleTextField({ label: 'Body Mass Index', value: calculateBMI({ heightFt, heightIn, weightLb }) }),
  ];
};

const YesNoField = ({ ifYes, ifNo, value, question, important, flat, isRoot }) => {
  const response = get(value, 'yes');
  const yesNo = isArray(response) ? response[0] : response;

  if (isRoot && get(ifYes, 'type') === 'List' && !!response) {
    return parseElement(ifYes, { value: response[1], omitUnderline: true, flat });
  }

  return [
    SingleTextField({
      label: question,
      value: yesNo ? 'Yes' : isUndefined(yesNo) || isNull(yesNo) ? '' : 'No',
      highlightAnswer: important,
      flat,
    }),
    !!response && ifYes ? parseElement(ifYes, { value: response[1], omitUnderline: true, flat }) : undefined,
    !!response && ifNo ? parseElement(ifNo, { value: response[1], omitUnderline: true, flat }) : undefined,
  ];
};

const OneOfField = ({ label, question, value, flat, ...field }) => {
  const response = isArray(value) ? get(value[1], 'option') : get(value, 'option');
  const subField = parseSubField(response, field);
  if (subField) {
    return [
      SingleTextField({ label: label || question, value: response[0], flat: flat }),
      parseElement(subField, { value: response[1], omitUnderline: false, flat, parent: label || question }),
    ];
  } else {
    return SingleTextField({ label: label || question, value: response, flat });
  }
};

const filterFn = el => {
  if (isArray(el) && el.length > 0) {
    return true;
  } else if (isArray(el)) {
    return false;
  } else if (isUndefined(el)) {
    return false;
  } else if (isNull(el)) {
    return false;
  }
  return true;
};

const filterElement = el => {
  if (isArray(el) && el.length > 0) {
    return el.filter(filterFn);
  }
  return filterFn(el) ? el : null;
};

const withUnderline =
  Component =>
  ({ omitUnderline, ...props }) => {
    if (props.flat) {
      return Component(props);
    }

    const el = filterElement(Component(props));
    if (el && !omitUnderline) {
      return { stack: [el, { svg: Line }], style: !props.isRoot ? 'subsection' : undefined };
    } else if (!props.isRoot) {
      return { stack: [el], style: 'subsection' };
    } else {
      return el;
    }
  };

const components = {
  Sections: Sections,
  Object: ObjectField,
  Text: withUnderline(SingleTextField),
  LongText: withUnderline(LongTextField),
  Checkboxes: withUnderline(CheckboxesField),
  List: withUnderline(ListField),
  DateOfBirth: withUnderline(DateOfBirthField),
  BodyMassIndex: withUnderline(BodyMassIndexField),
  YesNo: withUnderline(YesNoField),
  OneOf: withUnderline(OneOfField),
  CityStateZip: withUnderline(CityStateZip),
};

const parseElement = elementParserFactory(components);

// TODO: Important extract this from questions
export const getMedicationList = answers => {
  const [takesMedication, medicationAnswer] = get(answers, 'sections.medications.yes', []) || [];
  return [takesMedication, get(medicationAnswer, 'list', [])];
};

export const getBrainardMedicationList = answers => {
  const [takesMedication, medicationAnswer] = get(answers, 'sections.medications.schema.medication_q1.yes', []) || [];
  return [takesMedication, get(medicationAnswer, 'list', [])];
};

// TODO: Important extract this from questions
export const getAllergiesList = answers => {
  const schema = get(answers, 'sections.allergies.schema.q1.checkboxes', {});
  const medicationAllergies = get(schema, 'Allergic to Medications?', []) || [];

  const otherAllergies = [];

  if (get(schema, 'Allergic to adhesive?[0]', false)) {
    otherAllergies.push({ name: 'Adhesives', reaction: get(schema, 'Allergic to adhesive?[1].text') });
  }

  if (get(schema, 'Allergic to Latex?[0]', false)) {
    otherAllergies.push({ name: 'Latex', reaction: get(schema, 'Allergic to Latex?[1].schema.q7.text') });
  }
  const medicalAllergiesS = (get(medicationAllergies[1], 'list', []) || []).map(s => ({
    name: get(s, 'schema.medication.text'),
    reaction: get(s, 'schema.reaction.text'),
  }));
  const allergies = [...otherAllergies, ...medicalAllergiesS];
  return [allergies.length > 0, allergies];
};
const removeStartingOther = el => {
  if (el && Array.isArray(el)) {
    return el.filter(item => get(item, 'text', item) !== 'Other');
  }
  return el;
};

export const getBrainardAllergiesList = answers => {
  const schema = get(answers, 'sections.allergies.schema.q1.checkboxes', {});
  
  const otherAllergies = [];

  if (get(schema, 'Allergic to Adhesive tape?[0]', false)) {
    otherAllergies.push({ name: 'Adhesives', reaction: get(schema, 'Allergic to Adhesive tape?[1].text') });
  }

  if (get(schema, 'Allergic to Latex?[0]', false)) {
    otherAllergies.push({ name: 'Latex', reaction: get(schema, 'Allergic to Latex?[1].text') });
  }

  if (get(schema, 'Allergic to Eggs?[0]', false)) {
    otherAllergies.push({ name: 'Eggs', reaction: get(schema, 'Allergic to Eggs?[1].text') });
  }

  if (get(schema, 'Allergic to Iodine?[0]', false)) {
    otherAllergies.push({ name: 'Iodine', reaction: get(schema, 'Allergic to Iodine?[1].text') });
  }

  if (get(schema, 'Allergic to Shellfish?[0]', false)) {
    otherAllergies.push({ name: 'Shellfish', reaction: get(schema, 'Allergic to Shellfish?[1].text') });
  }
  const allergies = [...otherAllergies];

  return [allergies.length > 0, allergies];
};


export const haveBrainardMedication = answers => {
  const medication1 = get(answers, 'sections.medications.schema.medication_q1.yes', {});
  const medication2 = get(answers, 'sections.medications.schema.medication_q2.yes', {});
  const medication3 = get(answers, 'sections.medications.schema.medication_q3.yes', {});
  const noMedication = medication1 === false && medication2 === false && medication3 === false;

  return [
    noMedication?iconText(cbFullIcon, 'Do not take any prescription or OTC meds'):iconText(cbEmptyIcon,'Do not take any prescription or OTC meds')
  ];
};

export const makeGLPSection = answers => {
  const GLPmedi = get(answers, 'sections.medications.schema.medication_q2.yes[1].schema.GLPmedi.checkboxes');

  const dulaglutide = get(GLPmedi, 'Dulaglutide (Trulicity)[0]');
  const dulaglutideAgree = get(GLPmedi, 'Dulaglutide (Trulicity)[1].schema.dulaglutide_agreement.yes')?'Agree':'Disagree';
  const exenatide = get(GLPmedi, 'Exenatide (Byetta)[0]');
  const exenatideAgree = get(GLPmedi, 'Exenatide (Byetta)[1].schema.exenatide_agreement.yes')?'Agree':'Disagree';
  const EER = get(GLPmedi, 'Exenatide Extended Release (Bydureon BCise)[0]');
  const EERAgree = get(GLPmedi, 'Exenatide Extended Release (Bydureon BCise)[1].schema.EER_agreement.yes')?'Agree':'Disagree';
  const liraglutide = get(GLPmedi, 'Liraglutide (Victoza)[0]');
  const liraglutideAgree = get(GLPmedi, 'Liraglutide (Victoza)[1].schema.liraglutide_agreement.yes')?'Agree':'Disagree';
  const lixisenatide = get(GLPmedi, 'Lixisenatide (Adlyxin)[0]');
  const lixisenatideAgree = get(GLPmedi, 'Lixisenatide (Adlyxin)[1].schema.lixisenatide_agreement.yes')?'Agree':'Disagree';
  const semaglutide = get(GLPmedi, 'Semaglutide subcutaneous, tablet (Ozempic, Rybelsus)[0]');
  const semaglutideAgree = get(GLPmedi, 'Semaglutide subcutaneous, tablet (Ozempic, Rybelsus)[1].schema.semaglutide_agreement.yes')?'Agree':'Disagree';
  const tirzepatide = get(GLPmedi, 'Tirzepatide (Mounjaro)[0]');
  const tirzepatideAgree = get(GLPmedi, 'Tirzepatide (Mounjaro)[1].schema.tirzepatide_agreement.yes')?'Agree':'Disagree';

  const otherMedication = get(answers,'sections.medications.schema.medication_q3.yes[0]')
  const otherMedicationAgree = get(answers,'sections.medications.schema.medication_q3.yes[1].schema.nsaids_agreement.yes')?'Agree':'Disagree'

  const results =[
    dulaglutide ? `Dulaglutide (Trulicity) (${dulaglutideAgree})` : '',
    exenatide ? `Exenatide (Byetta) (${exenatideAgree})` : '',
    EER ? `Exenatide Extended Release (Bydureon BCise) (${EERAgree})` : '',
    liraglutide ? `Liraglutide (Victoza) (${liraglutideAgree})` : '',
    lixisenatide ? `Lixisenatide (Adlyxin) (${lixisenatideAgree})` : '',
    semaglutide ? `Semaglutide subcutaneous, tablet (Ozempic, Rybelsus) (${semaglutideAgree})` : '',
    tirzepatide ? `Tirzepatide (Mounjaro) (${tirzepatideAgree})` : '',
  ].filter(Boolean).join(', ');

  return {
    stack:[
      {
        text: [
          { text: 'GLP1 Medications: ', bold: true },
          results.length > 0 ? results : 'None'
        ]
      },
      otherMedication ? `Take Aspirin, Blood Thinners, or NSAIDS (${otherMedicationAgree})` : '',
    ],
    fontSize: 8,
    marginTop:10
  };
};

const makeFullMedicationSection = (takesMedication, medicationList, marginTop = 30) => {
  const medication =
    takesMedication && medicationList.length > 0
      ? [...medicationList, ...new Array(medicationList.length >= 6 ? 1 : 6 - medicationList.length)].map(entry =>
          !isEmpty(entry)
            ? [
                get(entry, 'schema.name.text', ''),
                get(entry, 'schema.dosage.text', ''),
                removeStartingOther(get(entry, 'schema.frequency.option', '')),
                get(entry, 'schema.purpose.text', ''),
                '',
              ]
            : [' ', ' ', ' ', ' ', '']
        )
      : [...[...new Array(6)].map(_ => [' ', ' ', ' ', ' ', ''])];

  return {
    stack: [
      {
        fontSize: 8,
        table: {
          headerRows: 1,
          widths: ['25%', '10%', '20%', '30%', '15%'],
          body: [
            [
              { text: 'Medication Name', style: 'tableheader' },
              { text: 'Dose', style: 'tableheader' },
              { text: 'Frequency', style: 'tableheader' },
              { text: 'Reason For Taking', style: 'tableheader' },
              { text: 'Last Taken -\n Date & Time', style: 'tableheader' },
            ],
            ...medication,
          ],
        },
      },
    ],
    marginTop: marginTop,
  };
};

const makeFullMedicationSection25 = (takesMedication, medicationList, marginTop = 5) => {
  const medication =
    takesMedication && medicationList.length > 0
      ? [...medicationList, ...new Array(medicationList.length >= 10 ? 1 : 10 - medicationList.length)].map(entry =>
          !isEmpty(entry)
            ? [
                get(entry, 'schema.name.text', ''),
                get(entry, 'schema.dosage.text', ''),
                removeStartingOther(get(entry, 'schema.frequency.option', '')),
                get(entry, 'schema.purpose.text', ''),
                '',
                'Today / Other',
              ]
            : [' ', ' ', ' ', ' ', '', 'Today / Other']
        )
      : [...[...new Array(10)].map(_ => [' ', ' ', ' ', ' ', '', 'Today / Other'])];

  return {
    stack: [
      {
        fontSize: 8,
        table: {
          headerRows: 1,

          widths: ['25%', '7%', '18%', '25%', '12%', '13%'],
          body: [
            [
              { text: 'Medication Name', style: 'tableheader' },
              { text: 'Dose', style: 'tableheader' },
              { text: 'Frequency', style: 'tableheader' },
              { text: 'Reason For Taking', style: 'tableheader' },
              { text: 'Last Taken -\n Date & Time', style: 'tableheader' },
              { text: 'Resume Medication', style: 'tableheader' },
            ],
            ...medication,
          ],
        },
      },
    ],
    marginTop: marginTop,
  };
};

const makeFullMedicationSectionView1Hospital27 = (takesMedication, medicationList) => {
  const medication =
    takesMedication && medicationList.length > 0
      ? [...medicationList, ...new Array(medicationList.length >= 11 ? 1 : 11 - medicationList.length)].map(entry =>
          !isEmpty(entry)
            ? [
                `${get(entry, 'schema.name.text', '')} ${get(entry, 'schema.dosage.text', '')}`,
                removeStartingOther(get(entry, 'schema.frequency.option', '')),
                get(entry, 'schema.purpose.text', ''),
                '',
                {
                  text: [iconText(cbEmptyIcon, 'Resume   '), iconText(cbEmptyIcon, 'STOP')],
                },
              ]
            : [
                ' ',
                ' ',
                ' ',
                ' ',
                {
                  text: [iconText(cbEmptyIcon, 'Resume   '), iconText(cbEmptyIcon, 'STOP')],
                },
              ]
        )
      : [
          ...[...new Array(11)].map(_ => [
            ' ',
            ' ',
            ' ',
            ' ',
            {
              text: [iconText(cbEmptyIcon, 'Resume   '), iconText(cbEmptyIcon, 'STOP')],
            },
          ]),
        ];

  return {
    stack: [
      {
        text: [
          { text: `Home Medications prior to Admission               `, bold: true },
          iconText(
            takesMedication ? cbEmptyIcon : cbFullIcon,
            'Check here if patient is not currently on any medication.'
          ),
        ],
      },
      {
        fontSize: 8,
        table: {
          headerRows: 1,
          widths: ['25%', '17%', '20%', '18%', '20%'],
          body: [
            [
              { text: 'Medication/Dose/Route', style: 'tableheader' },
              { text: 'Frequency', style: 'tableheader' },
              { text: 'Indication', style: 'tableheader' },
              { text: 'Last dose Date/Time', style: 'tableheader' },
              { text: 'At ASC Discharge', style: 'tableheader' },
            ],
            ...medication,
          ],
        },
      },
    ],
    marginTop: 5,
  };
};

const makeFullNewPrescriptionSection = (completed, medicationList, marginTop = 30) => {
  const medication =
    completed && medicationList.length > 0
      ? [...medicationList, ...new Array(medicationList.length >= 3 ? 1 : 3 - medicationList.length)].map(entry => {
          let medicationLabel = get(entry, 'medication.label', '');
          if (medicationLabel === 'Other') {
            medicationLabel = get(entry, 'medication.ifYes', '');
          }

          return !isEmpty(entry) ? [medicationLabel, get(entry, 'rteint', ''), '', '', ''] : [' ', ' ', ' ', ' ', ''];
        })
      : [...[...new Array(3)].map(_ => [' ', ' ', ' ', ' ', ''])];

  return {
    stack: [
      {
        fontSize: 8,
        table: {
          headerRows: 1,
          widths: ['30%', '10%', '20%', '25%', '15%'],
          body: [
            [
              { text: 'New Prescription', style: 'tableheader' },
              { text: 'Dose', style: 'tableheader' },
              { text: 'Frequency', style: 'tableheader' },
              { text: 'Last Taken', style: 'tableheader' },
              { text: 'Next Dose After Discharge', style: 'tableheader' },
            ],
            ...medication,
          ],
        },
      },
    ],
    marginTop: marginTop,
  };
};

const makeFullNewPrescriptionSectionView1Hospital27 = (completed, medicationList) => {
  const medication =
    completed && medicationList.length > 0
      ? [...medicationList, ...new Array(medicationList.length >= 10 ? 1 : 10 - medicationList.length)].map(entry => {
          let medicationLabel = get(entry, 'medication.label', '');
          if (medicationLabel === 'Other') {
            medicationLabel = get(entry, 'medication.ifYes', '');
          }

          return !isEmpty(entry) ? [medicationLabel + get(entry, 'rteint', ''), '', '', ''] : [' ', ' ', ' ', ''];
        })
      : [...[...new Array(10)].map(_ => [' ', ' ', ' ', ''])];

  return {
    stack: [
      { text: 'New/Changed Medications After Surgery', bold: true },
      {
        fontSize: 8,
        table: {
          headerRows: 1,
          widths: ['30%', '25%', '25%', '20%'],
          body: [
            [
              { text: 'Medication/Dose/Route', style: 'tableheader' },
              { text: 'Frequency', style: 'tableheader' },
              { text: 'Indication', style: 'tableheader' },
              { text: 'Duration', style: 'tableheader' },
            ],
            ...medication,
          ],
        },
      },
    ],
    marginTop: 5,
  };
};

const makeFullMedicationSectionView2Hospital28 = (takesMedication, medicationList, marginTop = 30) => {
  const medication =
    takesMedication && medicationList.length > 0
      ? [...medicationList, ...new Array(medicationList.length >= 6 ? 1 : 6 - medicationList.length)].map(entry =>
          !isEmpty(entry)
            ? [
                get(entry, 'schema.name.text', ''),
                get(entry, 'schema.dosage.text', ''),
                removeStartingOther(get(entry, 'schema.frequency.option', '')),
                '',
                '',
                '',
                '',
                '',
              ]
            : [' ', ' ', ' ', '', '', '', '', '']
        )
      : [...[...new Array(6)].map(_ => [' ', ' ', ' ', '', '', '', '', ''])];

  return {
    stack: [
      {
        fontSize: 8,
        table: {
          headerRows: 3,
          widths: ['25%', '10%', '10%', '25%', '10%', '6%', '8%', '6%'],
          body: [
            [
              { text: 'Medication Name', style: 'tableheader', rowSpan: 3 },
              { text: 'Dose', style: 'tableheader', rowSpan: 3 },
              { text: 'Frequency', style: 'tableheader', rowSpan: 3 },
              { text: 'Reason For Taking', style: 'tableheader', rowSpan: 3 },
              { text: 'Last Taken -\n Date & Time', style: 'tableheader', rowSpan: 3 },
              { text: 'SURGEON to Indicate:', style: 'tableheader', colSpan: 3 },
              '',
              '',
            ],
            ['', '', '', '', '', { text: 'CONTINUE', style: 'tableheader', colSpan: 3 }, '', ''],
            [
              '',
              '',
              '',
              '',
              '',
              { text: 'YES', style: 'tableheader' },
              { text: 'HOLD', style: 'tableheader' },
              { text: 'NO', style: 'tableheader' },
            ],
            ...medication,
          ],
        },
      },
    ],
    marginTop: marginTop,
  };
};

const makeAllergiesSection = (hasAllergies, allergiesList) => {
  const allergies = allergiesList.map(entry => ({
    text: `${get(entry, 'name', '')} ( ${get(entry, 'reaction', '-')} )`,
    color: get(entry, 'name', '') === 'Latex' ? 'red' : 'red',
  }));
  const rows = hasAllergies
    ? chunk(allergies, 4).map(pad(4))
    : [[{ text: 'No known allergies', color: 'red' }, '', '', ''], ...[...new Array(2)].map(_ => [' ', ' ', ' ', ' '])];

  return {
    layout: 'lightHorizontalLines',
    fontSize: 8,
    table: {
      headerRows: 0,
      widths: ['25%', '25%', '25%', '25%'],
      body: [[...[...new Array(4)].map(_ => ({ text: `Allergy (Reaction)`, style: 'tableheader' }))], ...rows],
    },
  };
};

const makeAllergiesSection27 = (hasAllergies, allergiesList) => {
  const allergiesText = hasAllergies
    ? allergiesList.map(entry => `${get(entry, 'name', '')} (${get(entry, 'reaction', '-')})`).join(', ')
    : 'No known allergies';

  const underline = '________________________________________________________';

  return {
    stack: [
      {
        columns: [
          {
            text: 'Allergies:',
            fontSize: 8,
            color: 'black',
            bold: true,
            width: 'auto',
          },
          {
            text: allergiesText,
            fontSize: 8,
            color: 'red',
            width: '*',
            margin: [5, 0, 0, 0],
          },
        ],
      },
      {
        text: underline,
        fontSize: 8,
        color: 'black',
        margin: [35, -10, 0, 0],
      },
    ],
  };
};

const makeNewPrescriptionSection = {
  fontSize: 8,
  table: {
    headerRows: 1,
    widths: ['30%', '10%', '20%', '25%', '15%'],
    body: [
      [
        { text: 'New Prescription', style: 'tableheader' },
        { text: 'Dose', style: 'tableheader' },
        { text: 'Frequency', style: 'tableheader' },
        { text: 'Last Taken', style: 'tableheader' },
        { text: 'Next Dose After Discharge', style: 'tableheader' },
      ],
      ...[...new Array(3)].map(x => [' ', '', '', '', '']),
    ],
  },
};

const makePatientSignature = {
  fontSize: 8,
  stack: [
    { text: 'The medications and allergies listed are accurate.', marginBottom: 10 },
    {
      columns: [
        { text: 'Date: ______________________________________________________' },
        { text: 'Patient Signature: _____________________________________________', alignment: 'right' },
      ],
    },
  ],
  marginTop: 20,
  marginBottom: 15,
};

const makePreOpRnSignature = {
  fontSize: 8,
  stack: [
    { text: 'The medications and allergies listed are accurate.', marginBottom: 10 },
    {
      columns: [
        { text: 'Date: ______________________________________________________' },
        { text: 'PREOP RN Signature: _____________________________________________', alignment: 'right' },
      ],
    },
  ],
  marginTop: 20,
  marginBottom: 15,
};

const makePreOpAndCirculatorSignature = {
  fontSize: 8,
  stack: [
    {
      columns: [
        { text: 'PreOp RN/CNA/MA Signature: _______________________________________' },
        { text: 'Circulator RN Signature: _______________________________________', alignment: 'right' },
      ],
    },
  ],
  marginBottom: 15,
};

const makePreAdmRnAndPreOpRnSignature = {
  fontSize: 8,
  stack: [
    {
      text: 'Pre-Admission RN completing: _______________________________________ Date/Time ____________________________',
    },
    {
      text: 'Pre-Op RN validating list: _______________________________________ Date/Time ____________________________ ',
    },
  ],
  marginTop: 15,
};

const docDefinitionTemplate = procedure => ({
  pageSize: 'LETTER',
  pageMargins: [40, 40, 40, 40],
  styles,
  defaultStyle: {
    fontSize: 9,
    lineHeight: 1.4,
  },
  footer: defaultFooter(procedure ? patientFooter(procedure) : undefined),
  pageBreakBefore: function (currentNode, followingNodesOnPage, nodesOnNextPage, previousNodesOnPage) {
    return currentNode.table && currentNode.pageNumbers.length > 1;
  },
});

const docDefinitionTemplate28 = procedure => ({
  pageSize: 'LETTER',
  pageMargins: [40, 40, 40, 40],
  styles,
  defaultStyle: {
    fontSize: 9,
    lineHeight: 1.4,
  },
  footer: defaultFooter(procedure),
  pageBreakBefore: function (currentNode, followingNodesOnPage, nodesOnNextPage, previousNodesOnPage) {
    return currentNode.table && currentNode.pageNumbers.length > 1;
  },
});

export const patientFooter = procedure => ({
  fontSize: 7,
  text: [
    { text: get(procedure, 'patientName', ''), opacity: 0.5, fontSize: 7 },
    get(procedure, 'startTime')
      ? {
          text: [
            {
              text: [
                { text: ' | ', opacity: 0.6, fontSize: 7 },
                { text: 'Date of Visit: ', opacity: 0.6, fontSize: 7 },
                { text: format(get(procedure, 'startTime'), 'MM/DD/YYYY'), opacity: 0.6, fontSize: 7 },
              ],
              opacity: 0.6,
            },
          ],
        }
      : undefined,
  ],
});

export const generateDetailedPdf = ({ questions, answers, procedure, hospitalName, scheduleProvider }) => {
  const header = makeHeader({ procedure, hospitalName, scheduleProvider });
  const body = parseElement(questions, { value: answers });

  const docDefinition = {
    ...docDefinitionTemplate(),
    info: { title: `Detailed Questionnaire Patient ${procedure.id}` },
    content: [header, body],
  };

  openPdf(createPdf(docDefinition), `Detailed_Questionnaire_Patient_${procedure.id}`);
};
export const generateMedicationPdf68 = ({
  questions,
  answers,
  procedure,
  preOpBy,
  preOpAt,
  hospitalName,
  scheduleProvider,
}) => {
  const header = makeHeader({
    procedure,
    hospitalName,
    preOpInfo: preOpAt ? { preOpBy, preOpAt } : undefined,
    scheduleProvider,
  });

  const footer = {
    stack: [
      {
        columns: [
          { text: [cbEmptyIcon, ' Resume Pre-Op Medication per Dr. ______________________________'], width: '60%' },
          { text: [cbEmptyIcon, ' Copy Given to Patient'] },
        ],
        marginBottom: 10,
        fontSize: 8,
      },
      {
        columns: [
          {
            stack: [
              { text: ['Patient/Responsible Adult’s Signature  _____________________________'], width: '60%' },
              {
                text: `${hospitalName} and its providers are not responsible for medications ordered by other organizations or providers`,
              },
            ],
          },
          {
            stack: [
              {
                text: 'PACU Signature ___________________________________________',
                alignment: 'right',
                marginBottom: 8,
              },
              { text: 'Date  __________________________ Time _____________________', alignment: 'right' },
            ],
          },
        ],
        fontSize: 8,
      },
    ],
    marginTop: 20,
  };

  const docDefinition = {
    ...docDefinitionTemplate(procedure),
    info: { title: `Entry Form Patient ${procedure.id}` },
    content: [
      { text: 'Medication Reconciliation', alignment: 'center', marginBottom: 10, fontSize: 12, bold: true },
      header,
      makeAllergiesSection(...getAllergiesList(answers)),
      makeFullMedicationSection(...getMedicationList(answers)),
      makePatientSignature,
      makePreOpAndCirculatorSignature,
      makeNewPrescriptionSection,
      footer,
    ],
  };

  openPdf(createPdf(docDefinition), `Entry_Form_Patient_${procedure.id}`);
};

const getPharmacyInfo = answers => {
  const { city, state, zipCode } = get(
    answers,
    'sections.demographicInformation.schema.pharmacy.yes[1].schema.additionalAddress.cityStateZip',
    {
      city: '',
      state: '',
      zipCode: '',
    }
  );
  const address = get(answers, 'sections.demographicInformation.schema.pharmacy.yes[1].schema.address.text', '');
  const hasAddress = !!address;
  const hasCity = !!city;
  const hasState = !!state;
  const hasZipCode = !!zipCode;
  const hasCityStateZip = hasCity || hasState || hasZipCode;
  const fullAddress =
    hasAddress || hasCityStateZip
      ? `${address}${hasAddress && hasCityStateZip ? ', ' : ''}${city}${hasCity || hasState ? ', ' : ''}${state}${
          hasZipCode ? ', ' : ''
        }${zipCode}`
      : undefined;
  return {
    name: get(answers, 'sections.demographicInformation.schema.pharmacy.yes[1].schema.name.text', undefined),
    fullAddress,
    phone: get(answers, 'sections.demographicInformation.schema.pharmacy.yes[1].schema.phone.text', undefined),
  };
};

export const generateMedicationPdf3 = ({
  questions,
  answers,
  procedure,
  preOpBy,
  preOpAt,
  hospitalName,
  scheduleProvider,
}) => {
  const header = makeHeader({
    procedure,
    hospitalName,
    preOpInfo: preOpAt ? { preOpBy, preOpAt } : undefined,
    scheduleProvider,
  });

  const { name, fullAddress, phone } = getPharmacyInfo(answers);
  const bottomBorder = [false, false, false, true];
  const noBorder = [false, false, false, false];
  const marginBottom = -4;
  const footer = {
    stack: [
      {
        columns: [
          { text: [cbEmptyIcon, ' Resume Pre-Op Medication per Dr. ______________________________'], width: '60%' },
          { text: [cbEmptyIcon, ' Copy Given to Patient'] },
        ],
        marginBottom: 10,
        fontSize: 8,
      },
      {
        columns: [
          {
            stack: [
              {
                text: `${hospitalName} and its providers are not responsible for medications ordered by other organizations or providers`,
              },
              {
                text: 'Preferred Pharmacy:',
                marginTop: 15,
                marginBottom: 2,
              },
              {
                table: {
                  widths: [32, 200],
                  body: [
                    [
                      { text: 'Name: ', border: noBorder, marginBottom },
                      {
                        text: `${name ?? ''}`,
                        border: bottomBorder,
                        marginBottom,
                      },
                    ],
                    [
                      { text: 'Address: ', border: noBorder, marginBottom },
                      {
                        text: `${fullAddress ?? ''}`,
                        border: bottomBorder,
                        marginBottom,
                      },
                    ],
                    [
                      { text: 'Phone: ', border: noBorder, marginBottom },
                      {
                        text: `${phone ?? ''}`,
                        border: bottomBorder,
                        marginBottom,
                      },
                    ],
                  ],
                },
              },
            ],
          },
          {
            stack: [
              {
                text: 'PACU Signature ___________________________________________',
                alignment: 'right',
                marginBottom: 8,
              },
              { text: 'Date  __________________________ Time _____________________', alignment: 'right' },
            ],
          },
        ],
        fontSize: 8,
      },
    ],
    marginTop: 20,
  };

  const docDefinition = {
    ...docDefinitionTemplate(procedure),
    info: { title: `Entry Form Patient ${procedure.id}` },
    content: [
      { text: 'Medication Reconciliation', alignment: 'center', marginBottom: 10, fontSize: 12, bold: true },
      header,
      makeAllergiesSection(...getAllergiesList(answers)),
      makeFullMedicationSection(...getMedicationList(answers)),
      makePatientSignature,
      makeNewPrescriptionSection,
      footer,
    ],
  };

  openPdf(createPdf(docDefinition), `Entry_Form_Patient_${procedure.id}`);
};

export const generateMedicationPdf = ({
  questions,
  answers,
  procedure,
  preOpBy,
  preOpAt,
  hospitalName,
  scheduleProvider,
  pacuCharts,
}) => {
  const header = makeHeader({
    procedure,
    hospitalName,
    preOpInfo: preOpAt ? { preOpBy, preOpAt } : undefined,
    scheduleProvider,
  });

  const footer = {
    stack: [
      {
        columns: [
          { text: [cbEmptyIcon, ' Resume Pre-Op Medication per Dr. ______________________________'], width: '60%' },
          { text: [cbEmptyIcon, ' Copy Given to Patient'] },
        ],
        marginBottom: 10,
        fontSize: 8,
      },
      {
        columns: [
          {
            text: `${hospitalName} and its providers are not responsible for medications ordered by other organizations or providers`,
          },
          {
            stack: [
              {
                text: 'PACU Signature ___________________________________________',
                alignment: 'right',
                marginBottom: 8,
              },
              { text: 'Date  __________________________ Time _____________________', alignment: 'right' },
            ],
          },
        ],
        fontSize: 8,
      },
    ],
    marginTop: 20,
  };

  const pacuChartMedication = pacuCharts.findIndex(chart => chart.questionnaire?.name === 'Medication');
  const medicationComplete = parseJson(pacuCharts[pacuChartMedication]?.questionnaireExchange?.answers)?.medication
    ?.value;
  const medicationList = parseJson(pacuCharts[pacuChartMedication]?.questionnaireExchange?.answers)?.medication?.ifYes;
  const docDefinition = {
    ...docDefinitionTemplate(procedure),
    info: { title: `Entry Form Patient ${procedure.id}` },
    content: [
      { text: 'Medication Reconciliation', alignment: 'center', marginBottom: 10, fontSize: 12, bold: true },
      header,
      makeAllergiesSection(...getAllergiesList(answers)),
      makeFullMedicationSection(...getMedicationList(answers)),
      makePatientSignature,
      makeFullNewPrescriptionSection(medicationComplete, medicationList),
      footer,
    ],
  };

  openPdf(createPdf(docDefinition), `Entry_Form_Patient_${procedure.id}`);
};

export const generateMedicationPdfView2Hospital25 = ({
  questions,
  answers,
  procedure,
  preOpBy,
  preOpAt,
  hospitalName,
  scheduleProvider,
}) => {
  const header = makeHeader({
    procedure,
    hospitalName,
    preOpInfo: preOpAt ? { preOpBy, preOpAt } : undefined,
    scheduleProvider,
  });

  const footer = {
    stack: [
      {
        columns: [
          {
            text: `${hospitalName} and its providers are not responsible for medications ordered by other organizations or providers`,
            width: '60%',
          },
          { text: [cbEmptyIcon, ' Copy Given to Patient'] },
        ],
        marginBottom: 10,
        fontSize: 8,
      },
      {
        columns: [
          {
            stack: [
              {
                text: 'MD Signature ______________________________',
                marginBottom: 8,
              },
              {
                text: 'Date  __________________________ Time _____________________',
              },
            ],
          },
          {
            stack: [
              {
                text: 'PREOP Signature ___________________________________________',
                alignment: 'right',
                marginBottom: 8,
              },
              { text: 'Date  __________________________ Time _____________________', alignment: 'right' },
            ],
          },
        ],
        fontSize: 8,
      },
    ],
    marginTop: 20,
  };

  const docDefinition = {
    ...docDefinitionTemplate(procedure),
    info: { title: `Entry Form Patient ${procedure.id}` },
    content: [
      { text: 'Medication Reconciliation', alignment: 'center', marginBottom: 10, fontSize: 12, bold: true },
      header,
      makeAllergiesSection(...getBrainardAllergiesList(answers)),
      haveBrainardMedication(answers),
      makeFullMedicationSection25(...getBrainardMedicationList(answers)),
      makeGLPSection(answers),
      makePatientSignature,
      makeNewPrescriptionSection,
      footer,
    ],
  };

  openPdf(createPdf(docDefinition), `Entry_Form_Patient_${procedure.id}`);
};

export const generateMedicationPdfHospital_31_1_2 = ({
  questions,
  answers,
  procedure,
  preOpBy,
  preOpAt,
  hospitalName,
  scheduleProvider,
}) => {
  const header = makeHeader({
    procedure,
    hospitalName,
    preOpInfo: preOpAt ? { preOpBy, preOpAt } : undefined,
    scheduleProvider,
  });

  const footer = {
    stack: [
      {
        columns: [
          { text: [cbEmptyIcon, ' Resume Pre-Op Medication per Dr. ______________________________'], width: '60%' },
          { text: [cbEmptyIcon, ' Copy Given to Patient'] },
        ],
        marginBottom: 10,
        fontSize: 8,
      },
      {
        columns: [
          {
            text: `${hospitalName} and its providers are not responsible for medications ordered by other organizations or providers`,
          },
          {
            stack: [
              {
                text: 'PACU Signature ___________________________________________',
                alignment: 'right',
                marginBottom: 8,
              },
              { text: 'Date  __________________________ Time _____________________', alignment: 'right' },
            ],
          },
        ],
        fontSize: 8,
      },
    ],
    marginTop: 20,
  };

  const docDefinition = {
    ...docDefinitionTemplate(procedure),
    info: { title: `Entry Form Patient ${procedure.id}` },
    content: [
      { text: 'Medication Reconciliation', alignment: 'center', marginBottom: 10, fontSize: 12, bold: true },
      header,
      makeAllergiesSection(...getAllergiesList(answers)),
      makePreOpAndCirculatorSignature,
      makeFullMedicationSection(...getMedicationList(answers), 5),
      makePatientSignature,
      makeNewPrescriptionSection,
      footer,
    ],
  };

  openPdf(createPdf(docDefinition), `Entry_Form_Patient_${procedure.id}`);
};

export const generateMedicationPdfHospital17 = ({
  questions,
  answers,
  procedure,
  preOpBy,
  preOpAt,
  hospitalName,
  scheduleProvider,
}) => {
  const header = makeHeader({
    procedure,
    hospitalName,
    preOpInfo: preOpAt ? { preOpBy, preOpAt } : undefined,
    scheduleProvider,
  });

  const footer = {
    stack: [
      {
        columns: [
          { text: [cbEmptyIcon, ' Resume Pre-Op Medication per Dr. ______________________________'], width: '60%' },
          { text: [cbEmptyIcon, ' Copy Given to Patient'] },
        ],
        marginBottom: 10,
        fontSize: 8,
      },
      {
        columns: [
          {
            stack: [
              {
                text: `${hospitalName} and its providers are not responsible for medications ordered by other organizations or providers`,
                marginBottom: 12,
              },
              {
                text: `________________________________________________________________________\nMD Signature`,
                marginBottom: 0,
              },
            ],
          },
          {
            stack: [
              {
                text: 'PACU Signature ___________________________________________',
                alignment: 'right',
                marginBottom: 8,
              },
              { text: 'Date  __________________________ Time _____________________', alignment: 'right' },
            ],
          },
        ],
        fontSize: 8,
      },
    ],
    marginTop: 20,
  };

  const docDefinition = {
    ...docDefinitionTemplate(procedure),
    info: { title: `Entry Form Patient ${procedure.id}` },
    content: [
      { text: 'Medication Reconciliation', alignment: 'center', marginBottom: 10, fontSize: 12, bold: true },
      header,
      makeAllergiesSection(...getAllergiesList(answers)),
      makeFullMedicationSection(...getMedicationList(answers)),
      makePatientSignature,
      makeNewPrescriptionSection,
      footer,
    ],
  };

  openPdf(createPdf(docDefinition), `Entry_Form_Patient_${procedure.id}`);
};

export const generateMedicationPdfView2Hospital28 = ({
  questions,
  answers,
  procedure,
  preOpBy,
  preOpAt,
  hospitalName,
  scheduleProvider,
}) => {
  const header = makeHeader28({
    procedure,
    hospitalName,
    preOpInfo: preOpAt ? { preOpBy, preOpAt } : undefined,
    scheduleProvider,
  });

  const footer = {
    stack: [
      {
        columns: [{ text: [cbEmptyIcon, ' Copy Given to Patient'] }],
        marginBottom: 10,
        fontSize: 8,
      },
      {
        columns: [
          {
            stack: [
              {
                text: `${hospitalName} and its providers are not responsible for medications ordered by other organizations or providers`,
                alignment: 'left',
                marginBottom: 8,
              },
              { text: 'Surgeon Signature  _______________________________________', alignment: 'left' },
            ],
          },
          {
            stack: [
              {
                text: 'PACU Signature ___________________________________________',
                alignment: 'right',
                marginBottom: 8,
              },
            ],
          },
        ],
        fontSize: 8,
      },
    ],
    marginTop: 20,
  };

  const docDefinition = {
    ...docDefinitionTemplate28(procedure),
    info: { title: `Entry Form Patient ${procedure.id}` },
    content: [
      { text: 'Medication Reconciliation', alignment: 'center', marginBottom: 10, fontSize: 12, bold: true },
      header,
      makeAllergiesSection(...getAllergiesList(answers)),
      makeFullMedicationSectionView2Hospital28(...getMedicationList(answers)),
      makePreOpRnSignature,
      footer,
    ],
  };

  openPdf(createPdf(docDefinition), `Entry_Form_Patient_${procedure.id}`);
};

export const generateMedicationPdfView1Hospital27 = ({
  questions,
  answers,
  procedure,
  preOpBy,
  preOpAt,
  hospitalName,
  scheduleProvider,
  pacuCharts,
}) => {
  const header = makeHeader27({
    procedure,
    hospitalName,
    preOpInfo: preOpAt ? { preOpBy, preOpAt } : undefined,
    scheduleProvider,
  });

  const footer = {
    stack: [
      {
        text: 'Physician signature: _______________________________________ Date _______________________ Time: _____________',
      },
      {
        text: 'PACU RN signature: _______________________________________ Date _______________________ Time: _____________',
      },
      {
        text: 'Patient signature: ________________________________________________________________________________________',
      },
      {
        text: `Signature indicates that I have received a copy of medications at discharge from Restore Orthopaedic Surgical Institute
      and I am responsible for the confidentiality of this list and for informing my primary care physician during my next visit.`,
        fontSize: 8,
      },
    ],
    marginTop: 15,
  };

  const pacuChartMedication = pacuCharts.findIndex(chart => chart.questionnaire?.name === 'Medication');
  const medicationComplete = parseJson(pacuCharts[pacuChartMedication]?.questionnaireExchange?.answers)?.medication
    ?.value;
  const medicationList = parseJson(pacuCharts[pacuChartMedication]?.questionnaireExchange?.answers)?.medication?.ifYes;
  const docDefinition = {
    ...docDefinitionTemplate(procedure),
    info: { title: `Entry Form Patient ${procedure.id}` },
    content: [
      { text: 'Medication Reconciliation Form', alignment: 'center', marginBottom: 10, fontSize: 12, bold: true },
      header,
      makeAllergiesSection27(...getAllergiesList(answers)),
      makeFullMedicationSectionView1Hospital27(...getMedicationList(answers)),
      makePreAdmRnAndPreOpRnSignature,
      makeFullNewPrescriptionSectionView1Hospital27(medicationComplete, medicationList),
      footer,
    ],
  };

  openPdf(createPdf(docDefinition), `Entry_Form_Patient_${procedure.id}`);
};
