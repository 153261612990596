import React, { useState, useMemo, Fragment } from 'react';
import styled from 'styled-components';
import { listProceduresFromToSubscription } from '../../../../../graph/procedures';
import get from 'lodash/get';
import first from 'lodash/first';
import last from 'lodash/last';
import groupBy from 'lodash/groupBy';
import rooms from '../../../../../graph/rooms';
import { ROOM_TYPES } from '../../../../entities/room/enums';
import {
  format,
  addMonths,
  subMonths,
  getDay,
  subDays,
  addDays,
  getMonth,
  getYear,
  getDaysInMonth,
  isWeekend,
} from 'date-fns';
import { add } from 'date-fns-3';
import chunk from 'lodash/chunk';
import { padWeek, WeeklyScheduleCalendar } from '../WeekView/WeekSchedule';
import Week from './Week';
import { Subscription } from '@apollo/client/react/components';
import { withQuery } from '@apollo/client/react/hoc';

const Schedule = styled.div`
  font-size: 0.75em;
`;

export const padWeeks = (days, showWeekends) => {
  const differenceBefore = getDay(first(days)) - (showWeekends ? 0 : 1);
  const differenceAfter = (showWeekends ? 6 : 5) - getDay(last(days));
  const padLeft = [...new Array(differenceBefore)].map((_, i) => subDays(first(days), differenceBefore - i));
  const padRight = [...new Array(differenceAfter)].map((_, i) => addDays(last(days), i + 1));
  return [...padLeft, ...days, ...padRight];
};

export const thisMonthDays = (date, showWeekends) => {
  const month = getMonth(date);
  const year = getYear(date);
  return [...new Array(getDaysInMonth(date))]
    .map((_, i) => new Date(year, month, i + 1))
    .filter(_ => showWeekends || !isWeekend(_));
};

const MonthlyScheduleCalendar = ({
  data,
  modal,
  setModal,
  weeks,
  rooms,
  date,
  setEditProcedureMonthly,
  openProcedureForm,
  canUseScheduleViewProcedure,
  showWeekends,
}) => {
  const monthlyProcedures = groupBy(get(data, 'proceduresFromTo', []), ({ orId, date }) => `${orId}_${date}`);

  return (
    <Schedule>
      {weeks.map((dates, i) => (
        <Week
          key={i}
          rooms={rooms}
          dates={dates}
          procedures={monthlyProcedures}
          dateSelection={date}
          onCellClick={val => setModal(val)}
          showDayNames={i === 0}
          firstWeek={i === 0}
          setEditProcedureMonthly={setEditProcedureMonthly}
          openProcedureForm={openProcedureForm}
          canUseScheduleViewProcedure={canUseScheduleViewProcedure}
          showWeekends={showWeekends}
        />
      ))}
    </Schedule>
  );
};

const MonthlySchedule = ({
  data,
  date,
  physician,
  setEditProcedureMonthly,
  openProcedureForm,
  canUseScheduleViewProcedure,
  showWeekends,
}) => {
  const [modalData, setModalData] = useState(null);

  const rooms = get(data, 'rooms', [])
    .filter(_ => _.type === ROOM_TYPES.OR)
    .sort((a, b) => a.name.localeCompare(b.name));

  const weeks = useMemo(
    () => chunk(padWeeks(thisMonthDays(date, showWeekends), showWeekends), showWeekends ? 7 : 5),
    [date, showWeekends]
  );

  const week = useMemo(() => padWeek(date, showWeekends), [date, showWeekends]);
  const numberOfWeeks = 4;

  return (
    <Fragment>
      <Subscription
        subscription={listProceduresFromToSubscription}
        variables={{
          from: format(first(first(weeks)), 'YYYY-MM-DD'),
          to: format(last(last(weeks)), 'YYYY-MM-DD'),
          physician: physician ? parseInt(physician, 10) : undefined,
          isCanceled: false,
        }}
      >
        {({ data }) => (
          <>
            {Array.from({ length: numberOfWeeks }).map((_, i) => (
              <WeeklyScheduleCalendar
                week={useMemo(() => padWeek(add(date, { weeks: i }), showWeekends), [date, showWeekends])}
                rooms={rooms}
                data={data}
                modal={modalData}
                setModal={setModalData}
                date={date}
                setEditProcedureMonthly={setEditProcedureMonthly}
                openProcedureForm={openProcedureForm}
                canUseScheduleViewProcedure={canUseScheduleViewProcedure}
                hideRoomName={i !== 0}
              />
            ))}
          </>
        )}
      </Subscription>
    </Fragment>
  );
};

export default withQuery(rooms.list)(MonthlySchedule);
