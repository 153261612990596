import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {
  addMinutes,
  differenceInHours,
  format,
  getHours,
  getMinutes,
  parse,
  setHours,
  setMinutes,
  setSeconds,
} from 'date-fns';
import get from 'lodash/get';
import React, { Fragment } from 'react';
import styled from 'styled-components';
import { withTheme } from '../../../../../se/theme';
import { HeaderCell, HeaderCellWithCount } from '../MonthlyView/Cell';

const Slots = styled.div`
  display: grid;
  grid-column: 2 / span 1;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(1, 1fr);
  grid-column-gap: 0.1666666667em;
  margin-bottom: 0.08333333333em;
`;

const Row = styled(Slots)`
  grid-template-rows: auto;
  margin-bottom: 0;
`;

const DayNumber = styled.div`
  font-size: 1.25em;
  color: ${withTheme(theme => theme.textColor.string())};
`;

const DateHeader = ({ date, procedureCounts = {} }) => (
  <Row>
    <Fragment key={`${date}`}>
      <HeaderCellWithCount>
        <span>{procedureCounts[format(date, 'YYYY-MM-DD')] || 0} procedures</span>
        <div className="day-wrapper">
          <DayNumber>{format(date, 'D')}</DayNumber>
        </div>
      </HeaderCellWithCount>
    </Fragment>
  </Row>
);

const Day = ({
  procedures = {},
  dateSelection,
  rooms,
  onCellClick,
  showDayNames,
  firstWeek,
  setEditProcedureMonthly,
  openProcedureForm,
  canUseScheduleViewProcedure,
  date,
  hideRoomName,
}) => {
  const classes = useStyles();
  // const hours = [8, 10, 12, 14, 16];

  const dayStart = setSeconds(setMinutes(setHours(date, 6), 0), 0);
  const dayEnd = setSeconds(setMinutes(setHours(date, 18), 0), 0);
  const HOUR_COUNT = differenceInHours(dayEnd, dayStart);
  const START_HOUR = getHours(dayStart);
  const hours = [...new Array(HOUR_COUNT + 1)].map((_, i) => `${i + START_HOUR}:00`);

  const dateString = format(date, 'YYYY-MM-DD');
  const procedureCounts = {
    [dateString]: Object.keys(procedures)
      .filter(key => key.includes(dateString))
      .reduce((sum, key) => sum + procedures[key].length, 0),
  };
  const dayName = format(date, 'dddd');

  const calculateGridRow = (startTime, dayStart) => {
    const procedureStart = parse(startTime);
    const startHour = getHours(dayStart);
    const procedureHour = getHours(procedureStart) - 12;
    const procedureMinute = getMinutes(procedureStart);

    console.debug('calc', procedureHour - 12, startHour, procedureHour - startHour);

    // +1 because grid rows start at 1
    return (procedureHour - startHour) * 2 + Math.floor(procedureMinute / 30) + 1;
  };

  return (
    <Box style={{ gap: '.25em' }} display="flex" flexDirection="column" flex={1} numberOfRows={showDayNames ? 3 : 2}>
      {showDayNames && (
        <Fragment key={`${dayName}`}>
          <HeaderCell style={{ height: 40 }}>{dayName}</HeaderCell>
        </Fragment>
      )}

      <DateHeader date={date} procedureCounts={procedureCounts} />

      {!hideRoomName && (
        <Box display="flex">
          {rooms.map((room, i) => (
            <Box
              className={classes.orName}
              gridColumn={i + 1}
              gridRow={`1 / span ${hours.length + 1}`}
              bgcolor="black"
              flex={1}
              key={`${room.id}-${i}`}
            >
              <Typography>{room.name}</Typography>
            </Box>
          ))}
        </Box>
      )}

      <Box
        className={classes.dayGrid}
        style={{
          gridTemplateColumns: `repeat(${rooms.length}, 1fr)`,
          gridTemplateRows: `repeat(${hours.length - 1}, 1fr)`,
        }}
      >
        {rooms.map((room, i) => {
          const proceduresX = get(procedures, `${room.id}_${format(date, 'YYYY-MM-DD')}`, []).map(procedure => ({
            ...procedure,
            start: parse(procedure.startTime),
            end: addMinutes(parse(procedure.startTime), procedure.duration),
            gridRow: calculateGridRow(procedure.startTime, dayStart) - 12,
          }));

          console.debug('proceduresX', proceduresX);

          return (
            <Fragment>
              {proceduresX.map(procedure => (
                <Box
                  className={classes.procedure}
                  style={{
                    gridColumn: i + 1,
                    gridRow: procedure.gridRow,
                  }}
                >
                  {procedure.procedureType}
                  {format(procedure.startTime, 'hh:mm')}
                </Box>
              ))}
              {/*<Slot*/}
              {/*  key={date}*/}
              {/*  date={date}*/}
              {/*  procedures={get(procedures, `${room.id}_${format(date, 'YYYY-MM-DD')}`, []).map(procedure => ({*/}
              {/*    ...procedure,*/}
              {/*    start: parse(procedure.startTime),*/}
              {/*    end: addMinutes(parse(procedure.startTime), procedure.duration),*/}
              {/*  }))}*/}
              {/*  setEditProcedureMonthly={setEditProcedureMonthly}*/}
              {/*  openProcedureForm={openProcedureForm}*/}
              {/*  canUseScheduleViewProcedure={canUseScheduleViewProcedure}*/}
              {/*  operationRooms={rooms}*/}
              {/*  operationRoom={room}*/}
              {/*/>*/}
            </Fragment>
          );
        })}
      </Box>
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  dayGrid: {
    display: 'grid',
    gridTemplateRows: 'auto',
    gridGap: '0.1666666667em',
    marginBottom: '0.6em',
    flex: 1,
    backgroundColor: 'rgba(255, 255, 255, 0.08)',
    minHeight: 0,
  },
  orName: {
    backgroundColor: 'rgba(255, 255, 255, 0.08)',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'rgba(255, 255, 255, 0.35)',
    flexFlow: 'column',
    padding: '0.583em 0.5em',
    height: '100%',
    textAlign: 'center',
    whiteSpace: 'nowrap',
  },
  procedure: {
    gridColumn: '3 / span 1',
    gridRow: '3 / span 3',
    backgroundColor: theme.palette.primary.dark,
    borderRadius: '0.25em',
    minWidth: 0,
    overflow: 'hidden',
    padding: '0.5em',
  },
}));

export default Day;
