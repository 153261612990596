import { alpha } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { Fragment } from 'react';
import { format, parse, addMinutes } from 'date-fns';
import styled, { css } from 'styled-components';
import responsive from '../../../../../se/utilities/responsive';
import get from 'lodash/get';
import { isSameMonth } from 'date-fns';
import { withTheme } from '../../../../../se/theme';
import Slot from './Slot';
import { HeaderCell, HeaderCellWithCount } from './Cell';

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(1, max-content) repeat(5, 1fr);
  grid-template-rows: repeat(${props => props.numberOfRows}, min-content);
  grid-column-gap: 0.1666666667em;
  margin-bottom: 0.33333em;
`;

const Slots = styled.div`
  display: grid;
  grid-column: 2 / span 6;
  grid-template-columns: ${props => (props.showWeekends ? 'repeat(7, 1fr)' : 'repeat(5, 1fr)')};
  grid-template-rows: repeat(1, 1fr);
  grid-column-gap: 0.1666666667em;
  margin-bottom: 0.08333333333em;
`;

const Row = styled(Slots)`
  grid-template-rows: auto;
  margin-bottom: 0;
`;

const OrCell = styled(HeaderCell)`
  ${responsive.md.andSmaller`
    writing-mode: vertical-rl;
    text-orientation: mixed;
  `}
`;

const Day = styled.div`
  font-size: 1.25em;
  color: ${withTheme(theme => theme.textColor.string())};
`;

const Timeline = styled.div`
  background: rgba(255, 255, 255, 0.08);
  min-height: 4em;
  position: relative;
  display: flex;
  justify-content: space-evenly;
  ${props =>
    props.makeRoom &&
    css`
      padding-top: 1.5em;
      min-height: 5.5em;
    `}
`;

const DateHeader = ({ dates = [], procedureCounts = {}, showWeekends }) => (
  <Row showWeekends={showWeekends}>
    {dates.map((day, i) => (
      <Fragment key={`${day}-${i}`}>
        <HeaderCellWithCount>
          <span>{procedureCounts[format(day, 'YYYY-MM-DD')] || 0} procedures</span>
          <div className="day-wrapper">
            <Day>{format(day, 'D')}</Day>
          </div>
        </HeaderCellWithCount>
      </Fragment>
    ))}
  </Row>
);

const VerticalTick = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  > label {
    position: absolute;
    top: 0;
    left: auto;
    opacity: 0.6;
  }

  > span {
    display: block;
    height: 100%;
    width: 0.08rem;
    background-color: rgba(255, 255, 255, 0.12);
  }
`;

const Rooms = ({ rooms }) => {
  const classes = useStyles();

  return (
    <div
      className={classes.rooms}
      style={{
        gridTemplateColumns: `repeat(${rooms.length}, 1fr)`,
      }}
    >
      {rooms.map((room, i) => (
        <div key={room.id}>{room.name}</div>
      ))}
    </div>
  );
};

const Week = ({
  dates = [],
  procedures = {},
  dateSelection,
  rooms,
  onCellClick,
  showDayNames,
  firstWeek,
  setEditProcedureMonthly,
  openProcedureForm,
  canUseScheduleViewProcedure,
  showWeekends,
}) => {
  const dayNames = showWeekends
    ? ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
    : ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];

  const hours = [8, 10, 12, 14, 16];

  const procedureCounts = dates.reduce((acc, date) => {
    const dateString = format(date, 'YYYY-MM-DD');
    acc[dateString] = Object.keys(procedures)
      .filter(key => key.includes(dateString))
      .reduce((sum, key) => sum + procedures[key].length, 0);
    return acc;
  }, {});

  return (
    <Container numberOfRows={showDayNames ? 3 : 2}>
      {showDayNames && (
        <Fragment>
          <HeaderCell style={{ marginBottom: '0.1666666667em' }} />
          <Row showWeekends={showWeekends}>
            {dayNames.map((day, i) => (
              <Fragment key={`${day}-${i}`}>
                <HeaderCell style={{ marginBottom: '0.1666666667em' }}>{day}</HeaderCell>
              </Fragment>
            ))}
          </Row>
        </Fragment>
      )}

      <HeaderCell />

      <DateHeader dates={dates} procedureCounts={procedureCounts} showWeekends={showWeekends} />
      <Row>
        {dates.map((day, i) => (
          <Rooms key={day} rooms={rooms} />
        ))}
      </Row>

      {rooms.map((room, i) => (
        <Fragment key={`${room.id}-${i}`}>
          <Slots showWeekends={showWeekends}>
            {dates.map((date, k) => (
              <Timeline key={`${date}-${k}`} makeRoom={firstWeek && i === 0}>
                {hours.map(h => (
                  <VerticalTick key={`hour-${h}`}>
                    {firstWeek && i === 0 && <label>{h}</label>}
                    <span />
                  </VerticalTick>
                ))}
                <Slot
                  grayed={!isSameMonth(date, dateSelection)}
                  key={date}
                  date={date}
                  procedures={get(procedures, `${room.id}_${format(date, 'YYYY-MM-DD')}`, []).map(procedure => ({
                    ...procedure,
                    start: parse(procedure.startTime),
                    end: addMinutes(parse(procedure.startTime), procedure.duration),
                  }))}
                  setEditProcedureMonthly={setEditProcedureMonthly}
                  openProcedureForm={openProcedureForm}
                  canUseScheduleViewProcedure={canUseScheduleViewProcedure}
                  operationRooms={rooms}
                  operationRoom={room}
                />
              </Timeline>
            ))}
          </Slots>
        </Fragment>
      ))}
    </Container>
  );
};

const useStyles = makeStyles(theme => ({
  rooms: {
    backgroundColor: alpha(theme.palette.text.primary, 0.08),
    display: 'grid',
    gridAutoFlow: 'column',
    textAlign: 'center',
  },
}));

export default Week;
