import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { differenceInHours, getHours, setHours, setMinutes, setSeconds } from 'date-fns';
import React, { Fragment } from 'react';
import { timelineUseStyles, YAxis } from '../timeline/Timeline';
import Day from './Day';

const Week = ({
  dates = [],
  procedures = {},
  dateSelection,
  rooms,
  onCellClick,
  showDayNames,
  firstWeek,
  setEditProcedureMonthly,
  openProcedureForm,
  canUseScheduleViewProcedure,
  hideRoomName,
}) => {
  const dayStart = setSeconds(setMinutes(setHours(dates[0], 6), 0), 0);
  const dayEnd = setSeconds(setMinutes(setHours(dates[0], 18), 0), 0);
  const HOUR_COUNT = differenceInHours(dayEnd, dayStart);
  const START_HOUR = getHours(dayStart);
  const hours = [...new Array(HOUR_COUNT + 1)].map((_, i) => `${i + START_HOUR}:00`);

  const classes = timelineUseStyles();
  const classesWeek = useStyles();

  return (
    <Box className={classesWeek.grid}>
      <YAxis style={{ paddingTop: hideRoomName ? 70 : 112 }}>
        {hours.map(tick => (
          <Box className={classes.tick} key={tick}>
            {tick}
          </Box>
        ))}
      </YAxis>
      {dates.map((date, i) => (
        <Day
          procedures={procedures}
          dateSelection={dateSelection}
          rooms={rooms}
          showDayNames={showDayNames}
          firstWeek={firstWeek}
          setEditProcedureMonthly={setEditProcedureMonthly}
          openProcedureForm={openProcedureForm}
          canUseScheduleViewProcedure={canUseScheduleViewProcedure}
          date={date}
          hideRoomName={hideRoomName}
        />
      ))}
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  grid: {
    display: 'flex',
    flex: '1 0 0',
    position: 'relative',
    overflow: 'hidden',
    gap: '0.5em',
    paddingLeft: '4em',
    paddingRight: '1.5em',
  },
}));

export default Week;
